import React from "react";
import "./Orders.css";
import { Container, Row, Col, Button, Modal } from "react-bootstrap";
import OrdersImg from "./../../Images/orders.png";
import { useState, useCallback } from "react";
import { useEffect } from "react";
import { BiFilter } from "react-icons/bi";
import { GrPowerReset } from "react-icons/gr";
import ConvertTime from "../../Common/ConvertTime";
import { useNavigate } from "react-router-dom";
import { fetchAllOrder } from "../../Actions/adminActions";
import { useDispatch, useSelector } from "react-redux";
import PaginationComponent from "../../Component/Pagination/PaginationControls";
import moment from "moment";
import debounce from "lodash.debounce";
import apiCall from "../../API/API";
import { toast } from "react-toastify";
const Orders = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { orders } = useSelector((state) => state.admin);
  const [filter, setFilter] = useState("none");
  const [data, setData] = useState([]);
  const [search, setsearch] = useState({
    status: "",
    amount: "",
    username: "",
    search: "",
    startDate: "",
    endDate: "",
  });
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [limitFilter, setLimitFilter] = useState(20);
  const [dateFilter, setDateFilter] = useState("default");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [currentApproveTxId, setCurrentApproveTxId] = useState(null);

  const fetchOrders = useCallback(
    debounce((params) => {
      dispatch(fetchAllOrder(params));
    }, 500),
    [dispatch]
  );

  const toggleFilter = () => {
    if (filter === "none") {
      setFilter("flex");
    } else {
      setFilter("none");
    }
  };
  const handlePagination = (page) => {
    // console.log("page",page)
    setCurrentPage(page);
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "startDate") {
      setStartDate(value);
    } else if (name === "endDate") {
      setEndDate(value);
    } else {
      setsearch((prev) => {
        const updatedSearch = { ...prev, [name]: value };
        Object.keys(updatedSearch).forEach((key) => {
          if (updatedSearch[key] === "") {
            delete updatedSearch[key];
          }
        });
        return updatedSearch;
      });
    }
    setCurrentPage(1);
    fetchOrders({
      page: 1,
      limit: limitFilter,
      ...Object.fromEntries(
        Object.entries(search).filter(([_, value]) => value !== "")
      ),
      ...(startDate && endDate ? { startDate, endDate } : {}),
    });
  };
  const handleLimitChange = (e) => {
    setLimitFilter(e.target.value);
  };

  const handleDateFilterChange = (e) => {
    const value = e.target.value;
    setDateFilter(value);
    const today = moment().endOf("day");
    let start = "";
    let end = "";
    switch (value) {
      case "today":
        start = today.startOf("day").format("YYYY-MM-DD");
        end = today.format("YYYY-MM-DD");
        break;
      case "yesterday":
        start = today.subtract(1, "days").startOf("day").format("YYYY-MM-DD");
        end = today.endOf("day").format("YYYY-MM-DD");
        break;
      case "this_week":
        start = today.startOf("week").format("YYYY-MM-DD");
        end = today.endOf("week").format("YYYY-MM-DD");
        break;
      case "this_month":
        start = today.startOf("month").format("YYYY-MM-DD");
        end = today.endOf("month").format("YYYY-MM-DD");
        break;
      default:
        start = "";
        end = "";
        break;
    }
    setStartDate(start);
    setEndDate(end);
  };

  const Addfilter = () => {
    const queryParams = {
      page: currentPage,
      limit: limitFilter,
      ...Object.fromEntries(
        Object.entries(search).filter(([_, value]) => value !== "")
      ),
    };

    if (startDate) {
      queryParams.startDate = startDate;
    }
    if (endDate) {
      queryParams.endDate = endDate;
    }

    dispatch(fetchAllOrder(queryParams));
  };

  const resetFilter = () => {
    const queryParams = {
      page: "1",
      limit: 20,
    };
    dispatch(fetchAllOrder(queryParams));
    setsearch({
      amount: "",
      username: "",
      search: "",
      startDate: "",
      endDate: "",
      status: "",
    });
    setLimitFilter(20);
    setStartDate("");
    setEndDate("");
    setDateFilter("default");
    setCurrentPage(1);
  };

  useEffect(() => {
    const queryParams = {
      page: currentPage,
      limit: limitFilter,
      ...Object.fromEntries(
        Object.entries(search).filter(([_, value]) => value !== "")
      ),
    };
    if (startDate && endDate) {
      queryParams.startDate = startDate;
      queryParams.endDate = endDate;
    }
    fetchOrders(queryParams);
  }, [dispatch, currentPage, limitFilter, search, startDate, endDate]);

  useEffect(() => {
    setTotalPages(orders?.totalPages);
    console.log("orders", orders);
  }, [orders]);

  // const handleConfirmApprove = (order_Id) => {
  //   setOrderid(order_Id);
  //   setShowApproveModal(true);
  // };

  // const handleCancelROI = () => {
  //   setShowApproveModal(false);
  //   setOrderid(null);
  // };

  const handleEnable = async (orderId) => {
    try {
      const { data, error } = await apiCall("POST", "/enableRoiOrders", {
        orderId,
      });

      if (error) {
        toast.error(error.message || "An error occurred");
      } else {
        toast.success(data.message || "ROI is enabled!");
        const queryParams = {
          page: currentPage,
          limit: limitFilter,
          ...Object.fromEntries(
            Object.entries(search).filter(([_, value]) => value !== "")
          ),
        };

        try {
          dispatch(fetchAllOrder(queryParams));
        } catch (error) {
          console.log("2222222222222222222", error);
        }
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  const handleDisable = async (orderId) => {
    try {
      const { data, error } = await apiCall("POST", "/disableRoiOrders", {
        orderId,
      });

      if (error) {
        toast.error(error.message || "An error occurred");
      } else {
        toast.success(data.message || "ROI is disabled!");
        const queryParams = {
          page: currentPage,
          limit: limitFilter,
          ...Object.fromEntries(
            Object.entries(search).filter(([_, value]) => value !== "")
          ),
        };

        try {
          dispatch(fetchAllOrder(queryParams));
        } catch (error) {
          console.log("2222222222222222222", error);
        }
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  return (
    <React.Fragment>
      <Container>
        <div className="pagePath">
          <p className="m-0">
            Home / <span>Orders</span>
          </p>
        </div>
        <Row id="filterRow">
          <span id="filterLogo">
            <div className="d-flex justify-content-between">
              <div onClick={() => toggleFilter()}>
                <i>
                  <BiFilter />
                </i>
                Filter
              </div>
            </div>
            <div className="align-content-center">
              Total Debited Amount : {orders?.Sum}
            </div>
          </span>

          <Row className="p-0 m-0" style={{ display: filter }}>
            <Col lg="2" md="4">
              <div>
                <input
                  type="text"
                  name="search"
                  placeholder="Search By Name, Username"
                  value={search.search}
                  onChange={handleChange}
                />
              </div>
            </Col>
            <Col lg="2" md="4">
              <div>
                <input
                  type="number"
                  name="amount"
                  placeholder="Search By Amount"
                  value={search.amount}
                  onChange={handleChange}
                />
              </div>
            </Col>
            {/*<Col lg="2" md="4">
              <div>
              
              <input type="text" name='username' placeholder="Search By UserName" value={search.username} onChange={handleChange} />
               </div>
            </Col>*/}
            <Col lg="2" md="4">
              <div>
                <input
                  type="date"
                  name="startDate"
                  placeholder="Start Date"
                  value={startDate}
                  onChange={handleChange}
                />
              </div>
            </Col>
            <Col lg="2" md="4">
              <div>
                <input
                  type="date"
                  name="endDate"
                  placeholder="End Date"
                  value={endDate}
                  onChange={handleChange}
                />
              </div>
            </Col>
            <Col lg="2" md="4">
              <div>
                <select
                  name="dateFilter"
                  value={dateFilter}
                  onChange={handleDateFilterChange}
                >
                  <option value="default">Default</option>
                  <option value="today">Today</option>
                  <option value="yesterday">Yesterday</option>
                  <option value="this_week">This Week</option>
                  <option value="this_month">This Month</option>
                </select>
              </div>
            </Col>
            <Col lg="2" md="4">
              <div>
                <select
                  name=""
                  value={limitFilter}
                  onChange={handleLimitChange}
                >
                  <option value="">--All--</option>
                  <option value="20">20</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                  <option value="200">200</option>
                </select>
              </div>
            </Col>
            <div>
              <button className="save filter" onClick={Addfilter}>
                Filter{" "}
                <i>
                  <BiFilter />
                </i>
              </button>
              <button className="cancel reset" onClick={resetFilter}>
                Reset{" "}
                <i>
                  <GrPowerReset />
                </i>
              </button>
            </div>
          </Row>
        </Row>
        <div id="directIncomeDiv">
          {/*<div id="ordersImg"><img src={OrdersImg} alt="" /></div>*/}

          <Row id="directTableRow" className="">
            <Col md="12" className="p-0">
              <div style={{ width: "100%" }}>
                <table>
                  <tr>
                    <th>S No.</th>
                    <th>Name</th>
                    <th>Username</th>
                    <th>Amount</th>
                    <th>ROI</th>
                    <th>Package Date&Time</th>
                    <th>Package Status</th>
                  </tr>
                  {orders?.data?.map((x, i) => {
                    return (
                      <tr>
                        <td>{i + 1 + limitFilter * (currentPage - 1)}</td>
                        <td>{x?.name}</td>
                        <td>{x?.username}</td>
                        <td>{x?.amount}</td>
                        {x?.status === 5 ? (
                          <td>
                            <button
                              className="btn btn-success block-btn"
                              onClick={() => handleEnable(x?.order_Id)}
                            >
                              Enable
                            </button>
                          </td>
                        ) : x?.status === 1 ? (
                          <td>
                            <button
                              className="btn btn-danger block-btn"
                              onClick={() => handleDisable(x?.order_Id)}
                            >
                              Disable
                            </button>
                          </td>
                        ) : (
                          <td></td>
                        )}

                        <td>
                          {moment(x?.createdAt).format(
                            "MM-DD-YYYY   &   HH:mm"
                          )}
                        </td>
                        {x?.status === 1 ? <td>Approved</td> : <td>Pending</td>}
                      </tr>
                    );
                  })}
                </table>
              </div>
            </Col>
          </Row>
        </div>
        <Row>
          <Col md="12">
            {
              <PaginationComponent
                currentPage={currentPage}
                totalPages={totalPages}
                onPageChange={handlePagination}
              />
            }
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
};

export default Orders;
