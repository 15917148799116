// src/api/api.js
import axios from 'axios';
import { toast } from 'react-toastify';

//  const API_BASE_URL = 'https://cfinanceapi.mlmreadymade.com/admin'; // Replace with your API base URL
//  const API_BASE_URL = 'https://apis.charity4u.org/admin';
// const API_BASE_URL = 'http://103.191.208.59:8000/admin';
const API_BASE_URL = 'https://apis.comes.finance/admin';


const apiCall = async (method, endpoint, data = null, showToast = false) => {
  const Authorization = await localStorage.getItem('authToken');
  let headers = {
    Authorization,
  };

  // Only set 'Content-Type' to 'application/json' if not sending FormData
  if (!(data instanceof FormData)) {
    headers['Content-Type'] = 'application/json';
  }

  try {
    // Handle GET request by using params instead of data
    const config = {
      method,
      url: `${API_BASE_URL}${endpoint}`,
      headers,
    };

    if (method === 'GET' && data) {
      config.params = data; // Attach data as query parameters for GET request
    } else {
      config.data = data; // Attach data to the body for POST, PUT, etc.
    }

    const response = await axios(config);

    console.log(endpoint, response.data);
    showToast && toast.success(response.data.message || "Request success");
    return { data: response.data, error: null };
  } catch (error) {
    console.log(endpoint, error);
    let code = 0;
    let errorMessage = 'An unexpected error occurred';

    if (error.response) {
      errorMessage = error.response.data.message || error.response.statusText;
      code = error.response.data.code || 0;
    } else if (error.request) {
      errorMessage = 'No response from server. Please try again later.';
    } else {
      errorMessage = error.message;
    }

    showToast && toast.error(errorMessage || "Request Failed"); // Show error message using toast
    return { data: null, error: { code, message: errorMessage } };
  }
};




export default apiCall;
