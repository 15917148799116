import React from "react";
import { Routes, Route } from "react-router-dom";
// import Check from "./Pages/Check/Check";
// import Test from "./Test";
// import WelcomeLetter from "./Pages/WelcomeLetter/WelcomeLetter";
// import ProfilePage from "./Pages/ProfilePage/ProfilePage";
// import EditProfile from "./Pages/EditProfile/EditProfile";
// import Accounts from "./Pages/Accounts/Accounts";
// import Generation from "./Pages/Generation/Generation";
// import WithdrawalReport from "./Pages/WithdrawalReport/WithdrawalReport";
// import KYC from "./Pages/KYC/KYC";
// import PinTransfer from "./Pages/PinTransfer/PinTransfer";
// import PinHistory from "./Pages/PinHistory/PinHistory";
// import PinBox from "./Pages/PinBox/PinBox";
// import MemberTopup from "./Pages/MemberTopup/MemberTopup";
// import DirectIncome from "./Pages/RoiIncome/RoiIncome";
// import LevelIncome from "./Pages/LevelIncome/LevelIncome";
// import RewardIncome from "./Pages/ROILevelIncome/ROILevelIncome";
import Orders from "./Pages/Orders/Orders";
// import Rewards from "./Pages/Rewards/Rewards";
// import ReportPage from "./Pages/ReportPage/ReportPage";
// import NewsEvent from "./Pages/NewsEvent/NewsEvent";
// import Support from "./Pages/Support/Support";
// import Tree from "./Pages/Tree/Tree";
import AllUsers from "./Pages/Users/AllUsers";
import Dashboard from "./Pages/Dashboard/Dashboard";
import PendingFund from "./Pages/FundRequest/Pending";
import ApprovedFund from "./Pages/FundRequest/Approved";
import RejectedFund from "./Pages/FundRequest/Rejected";
import PendingWith from "./Pages/withdrawal/PendingWith";
import ApprovedWith from "./Pages/withdrawal/ApprovedWith";
import RejectedWith from "./Pages/withdrawal/RejectedWith";
import PendingReward from "./Pages/Rewards/PendingReward";
import ApprovedReward from "./Pages/Rewards/ApprovedReward";
import RejectedReward from "./Pages/Rewards/RejectedReward";
import EditProfile from "./Pages/EditProfile/EditProfile";
import PayoutManagement from "./Pages/PayoutReport/PayoutControls";
import ROILevelIncome from "./Pages/PayoutReport/LevelIncome";
import RoyaltyIncome from "./Pages/PayoutReport/MonthlySalaryIncome";
import LevelIncome from "./Pages/PayoutReport/DirectIncome";
import DirectIncome from "./Pages/PayoutReport/RoiIncome";
import FundsManagement from "./Pages/FundRequest/FundControls";
import RewardsManagement from "./Pages/Rewards/RewardControls";
import WithdrawManagement from "./Pages/withdrawal/WithdrawControls";
import WithdrawDetails from "./Pages/withdrawal/WithdrawDetails";
import AddFunds from "./Pages/AddFund/AddFund";
import FundsHistory from "./Pages/FundHistory/FundHistory"
import BankDetails from "./Pages/BankReports/BankDetails";
import Web3 from "./Pages/BankReports/Web3";
import UPI from "./Pages/BankReports/Upi"
import BonanzaSetting from "./Pages/Bonanza/BonanzaSetting";
import News from "./Pages/News/News"
import Rank from "./Pages/Users/Rank"
import LevelRanks from "./Pages/Users/LevelRanks";
import PlanSetting from "./Pages/PlanSetting/PlanSetting";
import Reward from "./Pages/Users/Reward";
import SenderReceiverControls from "./Pages/SenderReceiver/SenderReceiverControls"
import PaymentSendReceive from "./Pages/SendReceive/PaymentSendReceive";
import DollarWithdrawalControls from "./Pages/withdrawalDollar/DollarWithdrawalControls";
import PendingWithDollar from "./Pages/withdrawalDollar/PendingWithDollar";
import ApprovedWithDollar from "./Pages/withdrawalDollar/ApprovedWithDollar";
import SpeedBonusIncome from "./Pages/PayoutReport/SpeedBonusIncome";
import PhAirdrop from "./Pages/PayoutReport/PhAirdrop";
import DailyLoginBonus from "./Pages/PayoutReport/DailyLoginBonus";
import TaskBonus from "./Pages/PayoutReport/TaskBonus";
import Adverstisement from "./Pages/Advertisement/Adverstisement";
import WithdrawnDapDetails from "./Pages/withdrawal/WithdrawDapDetails";
import Email from "./Pages/Email/Email";
//import PaginationComponent from "./Pages/Pagination/PaginationControls";
// import PlanSetting from "./Pages/PlanSetting/PlanSetting";
// import ROILevelIncome from "./Pages/ROILevelIncome/ROILevelIncome";
// import SingleLegGoal from "./Pages/singleLeg/SingleLegGoal";
// import PendingWith from "./Pages/withdrawal/PendingWith";
// import ApprovedWith from "./Pages/withdrawal/ApprovedWith";
// import RejecteddWith from "./Pages/withdrawal/RejectedWith";
// import RoyaltyIncome from "./Pages/ROILevelIncome/RoyaltyIncome";
// import AddPackage from "./Pages/AddPackage/AddPackage";
const NavPages = () => {
    return (
        <React.Fragment>
            <section>
                <Routes>
                    <Route path="/"   element={<Dashboard />} />
                    
                    <Route path="/approved_fund"   element={<ApprovedFund />} />
                    <Route path="/pending_fund"   element={<PendingFund />} />
                    <Route path="/rejected_fund"   element={<RejectedFund />} />
                    <Route path="/fund_controls"   element={<FundsManagement />} />
                    <Route path="/fund_history"   element={<FundsHistory />} />
                    <Route path="/rejected_reward"   element={<RejectedReward />} />
                    <Route path="/pending_reward"   element={<PendingReward />} />
                    <Route path="/approved_reward"   element={<ApprovedReward />} />
                    <Route path="/rewards"   element={<RewardsManagement />} />
                    <Route path="/withdraw_controls"   element={<WithdrawManagement />} />
                    <Route path="/pending_withdrawal"   element={<PendingWith />} />
                    <Route path="/approved_withdrawal"   element={<ApprovedWith />} />
                    <Route path="/rejected_withdrawal"   element={<RejectedWith />} />
                    <Route path="/withdraw_details"   element={<WithdrawDetails />} />
                    <Route path="/all_users"   element={<AllUsers />} />
                    <Route path="/payout_controls"   element={<PayoutManagement />} />
                    <Route path="/level_income"   element={<LevelIncome />} />
                    <Route path="/roi_income"   element={<DirectIncome />} />
                    <Route path="/monthly_salary_income"   element={<RoyaltyIncome />} />
                    <Route path="/speed_bonus_income"   element={<SpeedBonusIncome />} />
                    <Route path="/daily_login_bonus"   element={<DailyLoginBonus />} />
                    <Route path="/task_bonus"   element={<TaskBonus />} />
                    <Route path="/ph_airdrop"   element={<PhAirdrop />} />
                    <Route path="/direct_income"   element={<DirectIncome />} />
                    <Route path="/orders"   element={<Orders />} />
                    <Route path="/edit-profile"   element={<EditProfile />} />
                    <Route path="/add_fund"   element={<AddFunds />} />
                    <Route path="/bank"   element={<BankDetails />} />
                    <Route path="/chain"   element={<Web3 />} />
                    <Route path="/select_upi"   element={<UPI />} />
                    <Route path="/bonanza_setting"   element={<BonanzaSetting />} />
                    <Route path="/news"   element={<News />} />
                    <Route path="/rank" element={<Rank />} />
                    <Route path="/level_ranks" element={<LevelRanks />} />
                    <Route path="/plan_setting"   element={<PlanSetting />} />
                    <Route path="/reward" element={<Reward/>}></Route>
                    <Route path="/senderReceiver_controls" element={<SenderReceiverControls/>}></Route>
                    <Route path="/payment_senderReceiver" element={<PaymentSendReceive/>}></Route>
                    <Route path="/withdraw_controls_dollar"   element={<DollarWithdrawalControls />} />
                    <Route path="/pending_withdrawal_dollar"   element={<PendingWithDollar />} />
                    <Route path="/approved_withdrawal_dollar"   element={<ApprovedWithDollar />} />
                    <Route path="/advertisement"   element={<Adverstisement />} />
                    <Route path="/email"   element={<Email />} />
                    <Route
            path="/withdraw-dap-details"
            element={<WithdrawnDapDetails />}
          />
                    {/* <Route path="/check"   element={<Check />} />
                    <Route path="/plan_setting"   element={<PlanSetting />} />
                    <Route path="/pagination" element={<PaginationComponent />} />
                    <Route path="/test"   element={<Test />} />
                    <Route path="/welcome-letter"   element={<WelcomeLetter />} />
                    <Route path="/accounts"   element={<Accounts />} />
                    <Route path="/add_fund"   element={<AddFunds />} />
                    <Route path="/pending_withdrawal"   element={<PendingWith />} />
                    <Route path="/approved_withdrawal"   element={<ApprovedWith />} />
                    <Route path="/rejected_withdrawal"   element={<RejecteddWith />} />
                    <Route path="/generation"   element={<Generation />} />
                    <Route path="/deposit_withdrawal"   element={<WithdrawalReport />} />
                    <Route path="/kyc"   element={<KYC />} />
                    <Route path="/pin-transfer"   element={<PinTransfer />} />
                    <Route path="/pin-history"   element={<PinHistory />} />
                    <Route path="/pinbox"   element={<PinBox />} />
                    <Route path="/member-topup"   element={<MemberTopup />} />
                    <Route path="/roi_income"   element={<DirectIncome />} />
                    <Route path="/level-income"   element={<LevelIncome />} />
                    <Route path="/royalty_income"   element={<ROILevelIncome />} />
                    <Route path="/roilevel_income"   element={<RoyaltyIncome />} />
                    <Route path="/Single_Leg_Goal"   element={<SingleLegGoal/>} />
                    <Route path="/rewards"   element={<Rewards />} />
                    <Route path="/report"   element={<ReportPage />} />
                    <Route path="/news-event"   element={<NewsEvent />} />
                    <Route path="/support"   element={<Support />} />
                    <Route path="/tree"   element={<Tree />} />
                    <Route path="/active_package"   element={<AddPackage />} /> */}
                </Routes>
            </section>
        </React.Fragment>
    );
};

export default NavPages;