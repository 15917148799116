import React from "react";
import { useState, useCallback } from "react";
import { useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import FileUpload from "../../Component/File Upload/FileUpload";

const News = () => {
  return (
    <React.Fragment>
      <Container>
        <h2>News</h2>
        <FileUpload></FileUpload>
      </Container>
    </React.Fragment>
  );
};

export default News;
