import React, { useEffect, useState, useRef } from "react";
import "./Dashboard.css";
import { Col, Container, Row } from "react-bootstrap";
import { FaUsers } from "react-icons/fa6";
import { PiUserSwitchFill } from "react-icons/pi";
import { MdAttachMoney } from "react-icons/md";
import { FaUserSlash } from "react-icons/fa";
import { BiMoneyWithdraw } from "react-icons/bi";
import { Data } from "../../Common/Data";
import { useDispatch, useSelector } from "react-redux";
import { fetchDashboardData, fetchAllOrder } from "../../Actions/adminActions";
import MyChart from "../../Component/MyChart/MyChart";
import Chart from "../../Component/MyChart/Chart";
import apiCall from "./../../API/API";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import moment from "moment";

const Dashboard = () => {
  const hasFetchedData = useRef(false);
  const { orders } = useSelector((state) => state.admin);
  const [currency, setCurrency] = useState([]);
  const dispatch = useDispatch();
  const { dashboardData } = useSelector((state) => state.admin);
  useEffect(() => {
    if (!hasFetchedData.current) {
      dispatch(fetchDashboardData());
      hasFetchedData.current = true;
    }
  }, []);

  const fetchIcon = async () => {
    const { data, error } = await apiCall("GET", "/get-company-info");
    if (error) {
      toast.error(error.message || "An error occurred");
    } else {
      setCurrency(data?.company_info);
    }
  };

  useEffect(() => {
    if (!hasFetchedData.current) {
      fetchIcon();
      hasFetchedData.current = true;
    }
  }, []);

  useEffect(() => {
    const today = new Date();
    const formattedDate = today.toISOString().split("T")[0];
    const queryParams = {
      startDate: formattedDate,
      endDate: formattedDate,
    };
    dispatch(fetchAllOrder(queryParams));
  }, [dispatch]);

  const handleEnable = async (orderId) => {
    try {
      const { data, error } = await apiCall("POST", "/enableRoiOrders", {
        orderId,
      });

      if (error) {
        toast.error(error.message || "An error occurred");
      } else {
        toast.success(data.message || "ROI is enabled!");
        const queryParams = {};

        try {
          dispatch(fetchAllOrder(queryParams));
        } catch (error) {
          console.log("2222222222222222222", error);
        }
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  const handleDisable = async (orderId) => {
    try {
      const { data, error } = await apiCall("POST", "/disableRoiOrders", {
        orderId,
      });

      if (error) {
        toast.error(error.message || "An error occurred");
      } else {
        toast.success(data.message || "ROI is disabled!");
        const queryParams = {};

        try {
          dispatch(fetchAllOrder(queryParams));
        } catch (error) {
          console.log("2222222222222222222", error);
        }
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  return (
    <Container>
      <section>
        <h4 className="welcome">Welcome Back!</h4>
        <Row md="12">
          <Col md="3">
            <div className="dashboardAdminCard">
              <div>
                <h3>{dashboardData?.totalUsers}</h3>
                <p>Total Users</p>
              </div>
              <div className="dashboardIcon">
                <i>
                  <FaUsers />
                </i>
              </div>
            </div>
          </Col>
          <Col md="3">
            <div className="dashboardAdminCard">
              {" "}
              <div>
                <h3>{dashboardData?.activeUsers}</h3>
                <p>Active Users</p>
              </div>
              <div className="dashboardIcon">
                <i>
                  <PiUserSwitchFill />
                </i>
              </div>
            </div>
          </Col>
          <Col md="3">
            <div className="dashboardAdminCard">
              {" "}
              <div>
                <h3>{dashboardData?.todayActiveUsers || "0"}</h3>
                <p>Today Active Users</p>
              </div>
              <div className="dashboardIcon">
                <i>
                  <FaUsers />
                </i>
              </div>
            </div>
          </Col>
          <Col md="3">
            <div className="dashboardAdminCard">
              {" "}
              <div>
                <h3>{dashboardData?.blocked_user || "0"}</h3>
                <p>Blocked Users</p>
              </div>
              <div className="dashboardIcon">
                <i>
                  <FaUserSlash />
                </i>
              </div>
            </div>
          </Col>
        </Row>
        <Row md="12">
          <Col md="12">
            {dashboardData != null && dashboardData ? (
              <MyChart
                className="dashboardChart"
                totalWithdrawals={dashboardData?.totalWithdrawals}
                pendingWithdrawals={dashboardData?.pendingWithdrawals}
                approvedWithdrawals={dashboardData?.approvedWithdrawals}
                rejectedWithdrawals={dashboardData?.rejectedWithdrawals}
              />
            ) : (
              ""
            )}
          </Col>
        </Row>
        <section>
          <Row md="12">
            <Col md="6">
              <div className="dashboardInvestment">
                <div className="dashboardInvestmentHeading">
                  <h3>INVESTMENT</h3>
                  <div className="dashboardIcon">
                    <i>
                      <MdAttachMoney />
                    </i>
                  </div>
                </div>
                <div className="dashboardInvestmentContent">
                  <h4>Total </h4>
                  <p>{parseFloat(dashboardData?.totalInvestment).toFixed(2)}</p>
                </div>
                <div className="dashboardInvestmentContent">
                  <h4>Today</h4>
                  <p>{parseFloat(dashboardData?.todayInvestment).toFixed(2)}</p>
                </div>
                <div className="dashboardInvestmentContent">
                  <h4>Yesterday</h4>
                  <p>{"0"}</p>
                </div>
              </div>
            </Col>
            <Col md="6">
              <div className="dashboardInvestment">
                <div className="dashboardInvestmentHeading">
                  <h3>INCOME</h3>
                  <div className="dashboardIcon">
                    <i>
                      <MdAttachMoney />
                    </i>
                  </div>
                </div>
                <div className="dashboardInvestmentContent">
                  <h4>Total </h4>
                  <p>{parseFloat(dashboardData?.totalIncome).toFixed(2)}</p>
                </div>
                <div className="dashboardInvestmentContent">
                  <h4>Today</h4>
                  <p>{parseFloat(dashboardData?.todayIncome).toFixed(2)}</p>
                </div>
                <div className="dashboardInvestmentContent">
                  <h4>Yesterday</h4>
                  <p>{"0"}</p>
                </div>
              </div>
            </Col>
          </Row>
        </section>
      </section>

      <div id="directIncomeDiv">
        <h3 className="ordersHeading">RECENT ORDERS</h3>
        <Row id="directTableRow" className="">
          <Col md="12" className="p-0">
            <div style={{ width: "100%" }}>
              <table>
                <tr>
                  <th>S No.</th>
                  <th>Name</th>
                  <th>Username</th>
                  <th>Amount</th>
                  <th>ROI</th>
                  <th>Package Date&Time</th>
                  <th>Package Status</th>
                </tr>
                {orders?.data?.map((x, i) => {
                  return (
                    <tr>
                      <td>{i + 1}</td>
                      <td>{x?.name}</td>
                      <td>{x?.username}</td>
                      <td>{x?.amount}</td>
                      {x?.status === 5 ? (
                        <td>
                          <button
                            className="btn btn-success block-btn"
                            onClick={() => handleEnable(x?.order_Id)}
                          >
                            Enable
                          </button>
                        </td>
                      ) : x?.status === 1 ? (
                        <td>
                          <button
                            className="btn btn-danger block-btn"
                            onClick={() => handleDisable(x?.order_Id)}
                          >
                            Disable
                          </button>
                        </td>
                      ) : null}

                      <td>
                        {moment(x?.createdAt).format("MM-DD-YYYY   &   HH:mm")}
                      </td>
                      {x?.status === 1 ? <td>Approved</td> : <td>Pending</td>}
                    </tr>
                  );
                })}
              </table>
            </div>
          </Col>
        </Row>
      </div>
      {/* <section>
        <div className="table">
          <table>
            <thead>
              <tr>
                <th>S.No</th>
                <th>Name</th>
                <th>Username</th>
                <th>Email</th>
                <th>Join Date</th>
              </tr>
            </thead>
          </table>
        </div>
</section> */}
      {/* <div>
        <h4>New Customer List</h4>
        <button>All</button>
      </div> */}
      {/*<section>
        <div>
          <table>
            <thead>
              <tr>
                <th>S.No</th>
                <th>Name</th>
                <th>Username</th>
                <th>Email</th>
                <th>Join Date</th>
              </tr>
            </thead>
          </table>
        </div>
      </section>*/}
    </Container>
  );
};

export default Dashboard;
