import React, { useState, useEffect } from "react";
import apiCall from "./../../API/API";
import { toast } from "react-toastify";
import { CiCirclePlus } from "react-icons/ci";
import { Row, Col, Container, Button } from "react-bootstrap";

const PlanSetting = () => {
  const [formValues, setFormValues] = useState({
    income: "",
  });
  const [planData, setPlanData] = useState([]);

  const fetchRecords = async () => {
    try {
      const body = {
        planId: 2,
      };

      const { data, error } = await apiCall("POST", `/get-plan-id`, body);
      if (data) {
        toast.success(data.message);
        setPlanData(data);
        setFormValues({ income: data?.roi_income?.income || "" });
      } else if (error) {
        toast.error(error.message || "An error occurred");
      }
    } catch (e) {
      console.log(e.message);
    }
  };

  useEffect(() => {
    fetchRecords();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const handleUpdate = async () => {
    try {
      const body = { "roi_income.income": formValues.income };

      const { data, error } = await apiCall("POST", `/update-plan/2`, body);
      if (error) {
        toast.error(error.message || "An error occurred");
      } else {
        toast.success(data.message || "Roi updated successfully!");
        fetchRecords();
      }
    } catch (error) {
      toast.error(error.message || "An error occurred");
    }
  };

  return (
    <React.Fragment>
      <Container>
        <div className="bonanza-settings">
          <div className="input-row1">
            <h2>ROI setting</h2>
            <div className="input-group1">
              <label>Weekly Roi Income:</label>
              <input
                type="number"
                name="income"
                placeholder="Enter Roi Income"
                value={formValues.income}
                onChange={handleChange}
                required
              />
            </div>
            <div className="button-container">
              <Button type="submit" variant="primary" onClick={handleUpdate}>
                Update
              </Button>
            </div>
          </div>
        </div>
        {/* <div style={{ padding: "10px" }}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              marginBottom: "25px",
            }}
          >
            <button
              className="add-record-button1"
              onClick={handleAddRecordClick}
            >
              <i>
                <CiCirclePlus />
              </i>
              {""} Add Record
            </button>
          </div> */}

        {/* {showInputs && (
            <div className="bonanza-settings">
              <div className="input-row">
                <div className="input-group1">
                  <label>Trade Amount:</label>
                  <input
                    type="number"
                    placeholder="Enter Trade Amount"
                    name="trade_amount"
                    value={formValues.trade_amount}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="input-group1">
                  <label>Trade Profit:</label>
                  <input
                    type="number"
                    placeholder="Enter Trade Profit"
                    name="trade_profit"
                    value={formValues.trade_profit}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="input-group1">
                  <label>Start Date:</label>
                  <input
                    type="date"
                    name="from_date"
                    value={formValues.from_date}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="input-group1">
                  <label>End Date:</label>
                  <input
                    type="date"
                    name="to_date"
                    value={formValues.to_date}
                    onChange={handleChange}
                    required
                  />
                </div>
              </div>
              <div className="button-container">
                <Button type="submit" variant="primary" onClick={handleSave}>
                  Save
                </Button>
                <Button
                  variant="secondary"
                  className="ms-2"
                  onClick={handleCancel}
                >
                  Cancel
                </Button>
              </div>
            </div>
          )} */}

        {/* Table displaying records */}
        {/* <h2>Records</h2>
          {planData?.tradeData?.length > 0 ? (
            <div id="directIncomeDiv">
              <Row id="directTableRow" className="text-center">
                <Col md="12" className="p-0">
                  <div style={{ width: "100%" }}>
                    <table>
                      <tr>
                        <th>S.No.</th>
                        <th>Trade Amount</th>
                        <th>Trade Profit</th>
                        <th>Start Date</th>
                        <th>End Date</th>
                        <th>Edit</th>
                      </tr>
                      {planData?.tradeData?.map((record, index) => (
                        <tr key={index}>
                          <td data-label="S.No.">{index + 1}</td>
                          <td data-label="Trade Amount">
                            {record.trade_amount}
                          </td>
                          <td data-label="Trade Profit">
                            {record.trade_profit}
                          </td>
                          <td data-label="Start Date">{record.from_date}</td>
                          <td data-label="End Date">{record.to_date}</td>
                          <td>
                            <button
                              className="block-btn"
                              onClick={() => editRecord(record)}
                            >
                              Edit
                            </button>
                          </td>
                        </tr>
                      ))}
                    </table>
                  </div>
                </Col>
              </Row>
            </div>
          ) : (
            <p>No records found</p>
          )} */}
        {/* </div> */}
      </Container>
    </React.Fragment>
  );
};

export default PlanSetting;
