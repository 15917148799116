import React, { useState, useCallback } from "react";
import "./With.css";
import axios from "axios";
import { useEffect } from "react";
import ContractDetails from "../../Contracts/ContractDetails";
import { Container, Row, Col } from "react-bootstrap";
import { ethers } from "ethers";
import { useLocation, useNavigate } from "react-router-dom";
import { toastFailed, toastSuccess } from "../../Common/Data";
import { useSelector } from "react-redux";
import {
  approveWithdrawalRequest,
  fetchAllTransaction,
  rejectWithdrawalRequest,
} from "../../Actions/adminActions";
import { useDispatch } from "react-redux";
import CopyComponent from "../../Component/CopyComponent/CopyComponent";

const WithdrawnDetails = () => {
  const { BigInt } = window;
  const location = useLocation();
  const account = useSelector((state) => state.accounts.value);
  const dispatch = useDispatch();

  const {
    amountArray = [],
    walletAddressArray = [],
    usernameArray = [],
    tx_IdsArray = [],
  } = location.state || {};
  const navigate = useNavigate();

  async function shareDistribution(amountArray, walletAddressArray) {
    console.log(amountArray, walletAddressArray, "/...........");
    try {
      const { ethereum } = window;
      if (!ethereum) {
        console.log("Ethereum provider not found");
        return;
      }

      if (
        !Array.isArray(amountArray) ||
        !Array.isArray(walletAddressArray) ||
        amountArray.length === 0 ||
        walletAddressArray.length === 0 ||
        amountArray.length !== walletAddressArray.length
      ) {
        console.log("Invalid amount or wallet addresses");
        return;
      }

      const newAmount = amountArray.map((amount) => {
        // const multipliedAmount = parseFloat(amount) * 1e18;
        return ethers.utils.parseUnits(BigInt(amount).toString(), 18);
      });
      if (account != null && account.length > 0) {
        const provider = new ethers.providers.Web3Provider(ethereum);
        const signer = provider.getSigner();
        const busdInstance = new ethers.Contract(
          ContractDetails.contract,
          ContractDetails.contractABI,
          signer
        );

        let inc = await busdInstance.sendTokensToMultipleAddresses(
          newAmount,
          walletAddressArray
        );
        console.log(inc);
        toastSuccess("Withdrawal successful!");
        navigate("/dashboard");
        await dispatch(approveWithdrawalRequest(tx_IdsArray));
      } else {
        toastFailed("Connect your wallet");
      }
    } catch (error) {
      console.error("Withdrawal failed:", error);
      toastFailed(error?.error?.data?.message);
    }
  }

  const handleWithDap = async () => {
    console.log("222222222222222222", tx_IdsArray);
    await shareDistribution(amountArray, walletAddressArray);
  };

  const handleNotWithDap = () => {
    navigate("/dashboard/withdraw_controls");
  };

  return (
    <React.Fragment>
      <Container>
        <Row id="filterRow"></Row>
        <div id="directIncomeDiv">
          <div className="button-container">
            <button className="action-btn" onClick={handleWithDap}>
              Confirm
            </button>
            <button className="action-btn" onClick={handleNotWithDap}>
              Cancel
            </button>
          </div>

          <Row id="directTableRow" className="text-center">
            <Col md="12" className="p-0">
              <div style={{ width: "100%", display: "flex" }}>
                <table>
                  <tr>
                    <th>S. No.</th>
                    <th>Username</th>
                    <th>Wallet Address</th>
                    <th>Amount</th>
                  </tr>
                  <tbody>
                    {amountArray.map((amount, i) => (
                      <tr key={i}>
                        <td>{i + 1}</td>
                        <td>{usernameArray[i]}</td>
                        <td>
                          <div className="keyValue">
                            {walletAddressArray[i]}
                            <CopyComponent value={walletAddressArray[i]} />
                          </div>
                        </td>
                        <td>{amount}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </Col>
          </Row>
        </div>
      </Container>
    </React.Fragment>
  );
};

export default WithdrawnDetails;
