import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import apiCall from './../../API/API'; 
import "./Upi.css";
import { Container, Row, Col,Button } from "react-bootstrap";
import AlertMessage from '../../Component/AlertMessage/AlertMessage';
import { fetchAllTransaction } from '../../Actions/adminActions';
import { BsCheckCircle } from 'react-icons/bs';
import { BiErrorCircle } from 'react-icons/bi';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Upi = () => {
  const [name, setName] = useState("");
  const [upiId, setUpiId] = useState("");
  const [loading, setLoading] = useState(false); 
  const [error, setError] = useState(''); 
  const [success, setSuccess] = useState('null');
  const [upiData, setUpiData]=useState([]);
  const [defaultUpiId, setDefaultUpiId] = useState(null);
  const { paymentRequests } = useSelector((state) => state.admin);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const fetchUpiData = async () => {
    const { data, error } = await apiCall('GET', '/get-payment-method',null,false);
    //console.log("data111111111111",data)
    if (error) {
      setError(error.message);
    } else {
      setUpiData(data?.activeOptions?.manual?.upi);
      const defaultUpi = data?.activeOptions?.manual?.upi?.find(upi => upi.isDefault);
      setDefaultUpiId(defaultUpi?.upiId || null);
    }
  };

  useEffect(() => {
    fetchUpiData();
  }, []);
  
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError('');
    setSuccess('null');

    const { data, error } = await apiCall('POST', '/add-upi-details', { name, upiId });
    console.log("data",data)
    console.log("error",error)

    if (error) {
      toast.error(error.message || "An error occurred");
    } else {
      toast.success(data.message || "UPI id submitted successfully");
      setName('');
      setUpiId('');
      fetchUpiData();
    }

    setLoading(false);
  };

  const handleDelete = async (upiId) => {
    const { data, error } = await apiCall('POST', `/delete-upi-details`,{upiId});
    if (error) {
      toast.error(error.message || "An error occurred");
    } else {
      toast.success(data.message || "UPI id deleted successfully");
      fetchUpiData();
    }
  };

  const handleDefault = async (upiId) => {
    const {data,error} = await apiCall('POST',`/set-default-UPI-details`,{upiId});
    if(error) {
      toast.error(error.message || "An error occurred");
    } else {
      toast.success(data.message || "UPI id set default successfully");
      fetchUpiData();
    }
  };

  return (
    <React.Fragment>
      <Container className="mt-4">
        <section id="upiForm">
          <div className="d-flex justify-content-center">
            <h2>UPI Form</h2>
          </div>
          <section id="formContent">
            <div className="form">
              <Row>
                <Col lg={6} style={{ margin: 'auto' }}>
                  <form onSubmit={handleSubmit} className="registerForm">
                    <div id="formFields">
                      <div className="mb-3">
                        <label htmlFor="name">Name</label>
                        <input
                          type="text"
                          id="name"
                          className="form-control"
                          value={name}
                          placeholder="Enter your name"
                          onChange={(e) => setName(e.target.value)}
                          required
                        />
                      </div>
                      <div className="mb-3">
                        <label htmlFor="upiId">UPI ID</label>
                        <input
                          type="text"
                          id="upiId"
                          className="form-control"
                          value={upiId}
                          placeholder="Enter your UPI ID"
                          onChange={(e) => setUpiId(e.target.value)}
                          required
                        />
                      </div>
                      <Button type="submit" variant="primary" disabled={loading}>
                        {loading ? 'Submitting...' : 'Submit'}
                      </Button>
                      <Button
                        variant="secondary"
                        className="ms-2"
                        onClick={() => {
                          setName('');
                          setUpiId('');
                        }}
                      >
                        Cancel
                      </Button>
                    </div>
                  </form>
                </Col>
              </Row>
            </div>
          </section>
        </section>
        {/* {success === 'true' ? (
          <AlertMessage
            msg="UPI information submitted successfully"
            icon={<BsCheckCircle />}
          />
        ) : success === 'false' ? (
          <AlertMessage
            msg={error || 'Submission failed'}
            icon={<BiErrorCircle />}
          />
        ) : ''} */}
        <div id="directIncomeDiv">
        <Row id="directTableRow" className="text-center">
                        <Col md="12" className="p-0">
                            <div style={{ width: "100%" }}>
                                <table>
                                    <tr>
                                        <th>S No.</th>
                                        <th>name</th>
                                        <th>UPI ID</th>   
                                        <th>Delete UPI</th>   
                                        <th>Set Default</th>                          
                                    </tr>
                                    {
                                       Array.isArray(upiData) &&  upiData?.map((x, i) => {
                                            return (
                                                <tr>
                                                    <td className="text-center">{(i+1)/*+(limitFilter*(currentPage-1))*/}</td>
                                                    <td className="text-center">{x?.name}</td>
                                                    <td className="text-center">{x?.upiId}</td>
                                                    <td className="text-center">
                                                      <button 
                                                        className="block-btn"
                                                        onClick={() => handleDelete(x?.upiId)}>
                                                        Delete UPI
                                                      </button>
                                                    </td>
                                                    <td className="text-center">
                                                      <button
                                                      className={`block-btn ${defaultUpiId === x?.upiId ? 'default-btn' : ''}`}
                                                      onClick={() => handleDefault(x?.upiId)}
                                                      disabled={defaultUpiId === x?.upiId}>
                                                        {defaultUpiId === x?.upiId ? 'Default' : 'Set Default'}
                                                      </button>
                                                    </td>
                                                </tr>
                                            )
                                        })
                                    }
                                </table>
                            </div>
                        </Col>
                    </Row>
        </div>
        <ToastContainer className="toast-container" />
      </Container>
    </React.Fragment>
  );
};

export default Upi;
