import React, { useEffect, useState } from "react";
import { Row, Col, Container, Button } from "react-bootstrap";
import "./AddFund.css";
import apiCall from "./../../API/API";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const AddFunds = () => {
  const [userName, setUserName] = useState("");
  const [amount, setAmount] = useState("");
  const [loading, setLoading] = useState(false);
  const token = localStorage.getItem("authToken");
  const [incomeData, setIncomeData] = useState([]);
  const [selectWallet, setSelectWallet] = useState();
  const [typingTimeout, setTypingTimeout] = useState(null);
  const [existUserName, setExistUserName] = useState();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    if (!userName || !amount) {
      toast.error("Please fill in all fields");
      return;
    }

    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: token,
        },
      };
      const { data, error } = await apiCall(
        "post",
        "/add_fund",
        {
          amount: amount,
          username: userName,
          wallet_name: selectWallet,
        },
        config
      );

      if (data) {
        // toast.success(data.message);
        setUserName("");
        setAmount("");
      } else if (error) {
        // toast.error(error.message || "An error occurred");
      }
    } catch (error) {
      toast.error(error.response?.data?.message || "An error occurred");
    }
    setLoading(false);
  };

  var x = 0;

  useEffect(() => {
    if (x == 0) {
      fetchWallets();
      x++;
    }
  }, []);
  const fetchWallets = async (e) => {
    try {
      const { data, error } = await apiCall("get", "/add_fund_wallet");
      console.log(data, "....");
      setIncomeData(data?.activity);
      console.log(data);
    } catch (error) {
      console.log(error);
    }
  };

  const isUserExist = async () => {
    setLoading(true);

    if (!userName) {
      setExistUserName(". . .");
      setLoading(false);
      return;
    }

    try {
      const { data, error } = await apiCall("post", "/check_username", {
        username: userName,
      });
      if (data) {
        setExistUserName(data.name);
      } else if (error) {
        setExistUserName("User not exists");
      }
    } catch (error) {
      toast.error(error.response?.data?.message || "An error occurred");
    }
    setLoading(false);
  };

  useEffect(() => {
    // Clear the timeout when the user is typing
    if (typingTimeout) {
      clearTimeout(typingTimeout);
    }

    // Set a new timeout to call the API after the user stops typing
    setTypingTimeout(
      setTimeout(() => {
        isUserExist();
      }, 1000) // 500ms debounce delay
    );

    // Cleanup the timeout when the component is unmounted or when typing continues
    return () => clearTimeout(typingTimeout);
  }, [userName]);

  return (
    <React.Fragment>
      <Container className="mt-4">
        <section id="add-funds-section">
          <div className="d-flex justify-content-center">
            <h2>Add Funds</h2>
          </div>
          <section id="formContent">
            <div className="form">
              <Row>
                <Col lg={6} style={{ margin: "auto" }}>
                  <form onSubmit={handleSubmit}>
                    <div id="formFields">
                      <div className="mb-3">
                        <div className="d-flex">
                          <label htmlFor="userName">User Name:</label>
                          <label
                            style={{ textAlign: "end" }}
                            htmlFor="userName"
                          >
                            {loading && userName ? "Loading..." : existUserName}
                          </label>
                        </div>
                        <input
                          type="text"
                          id="userName"
                          className="form-control"
                          value={userName}
                          onChange={(e) => setUserName(e.target.value)}
                          placeholder="Enter your Username"
                        />
                      </div>

                      <div className="mb-3">
                        <label htmlFor="choosebank">Select Wallet Type</label>
                        <select
                          className="selectBox"
                          name=""
                          id=""
                          value={selectWallet}
                          onChange={(e) => setSelectWallet(e.target.value)}
                        >
                          <option value="default">--Select Type--</option>
                          {Array.isArray(incomeData) &&
                            incomeData?.map((x, i) => {
                              return <option value={x?.slug}>{x?.name}</option>;
                            })}
                        </select>
                      </div>
                      <div className="mb-3">
                        <label htmlFor="amount">Amount:</label>
                        <input
                          type="number"
                          id="amount"
                          className="form-control"
                          value={amount}
                          onChange={(e) => setAmount(e.target.value)}
                          placeholder="Enter Amount"
                        />
                      </div>
                      <Button
                        type="submit"
                        variant="primary"
                        disabled={loading}
                      >
                        {loading ? "Submitting..." : "Submit"}
                      </Button>
                      <Button
                        variant="secondary"
                        className="ms-2"
                        onClick={() => {
                          setUserName("");
                          setAmount("");
                        }}
                      >
                        Cancel
                      </Button>
                    </div>
                  </form>
                </Col>
              </Row>
            </div>
          </section>
        </section>
        <ToastContainer className="toast-container" />
      </Container>
    </React.Fragment>
  );
};

export default AddFunds;
