import { createStore, combineReducers, applyMiddleware } from "redux";
import { thunk } from "redux-thunk";
import adminReducer from "../Reducers/adminReducer";
import accountsReducer from "../Reducers/accountsReducer";
const rootReducer = combineReducers({
  admin: adminReducer,
  accounts: accountsReducer,
});

const store = createStore(rootReducer, applyMiddleware(thunk));

export default store;
