import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { IoWallet } from "react-icons/io5";
import WalletConnectProvider from "@walletconnect/web3-provider";
import Web3Modal from "web3modal";
import { providers } from "ethers";
import Change from "./../Common/StringToSub";
import {
  setAccountRequest,
  setAccountSuccess,
  setAccountFailure,
} from "../Actions/accountActions";
import "./../App.css";
import { Data } from "../Common/Data";

const ConnectButton = ({ screenWidth, toggle }) => {
  const [web3Modal, setWeb3Modal] = useState(null);
  const dispatch = useDispatch();

  const account = useSelector((state) => state.accounts.value);
  // console.log(account, ".....");
  const loading = useSelector((state) => state.accounts.loading);
  const error = useSelector((state) => state.accounts.error);
  const checkWalletIsConnected = () => {
    const { ethereum } = window;
    if (!ethereum) {
      dispatch(
        setAccountFailure({ code: 1, message: "MetaMask is not installed" })
      );
    }
  };

  useEffect(() => {
    checkWalletIsConnected();
    const providerOptions = {
      walletconnect: {
        package: WalletConnectProvider,
        options: {
          infuraId: "05f311673625f063cd5c0736f5bb17b0",
        },
      },
    };

    const newWeb3Modal = new Web3Modal({
      cacheProvider: true,
      providerOptions,
    });

    setWeb3Modal(newWeb3Modal);
  }, []);

  useEffect(() => {
    if (web3Modal && web3Modal.cachedProvider) {
      connectWallet();
    }
  }, [web3Modal]);

  const connectWallet = async () => {
    dispatch(setAccountRequest());
    try {
      const provider = await web3Modal.connect();
      addListeners(provider);
      const ethersProvider = new providers.Web3Provider(provider);
      const userAddress = await ethersProvider.getSigner().getAddress();
      dispatch(setAccountSuccess(userAddress));
    } catch (error) {
      dispatch(
        setAccountFailure({ code: 2, message: "Failed to connect wallet" })
      );
    }
  };

  const addListeners = (web3ModalProvider) => {
    web3ModalProvider.on("accountsChanged", (accounts) => {
      if (accounts.length) {
        dispatch(setAccountSuccess(accounts[0]));
      } else {
        dispatch(
          setAccountFailure({ code: 3, message: "Account disconnected" })
        );
      }
    });

    web3ModalProvider.on("chainChanged", async () => {
      connectWallet();
    });
  };

  function linkBlockchain() {
    console.log(Data?.testnetLink + account);
    window.open(Data?.testnetLink + account);
  }

  return (
    <>
      {account != null ? (
        <span
          id="connectButtonAddress"
          onClick={linkBlockchain}
          style={{
            cursor: "pointer",
            right: screenWidth > 800 && toggle == "-260px" ? "0px" : "260px",
          }}
        >
          <i>
            <IoWallet />
          </i>
          {Change(account)}
        </span>
      ) : (
        <button
          onClick={connectWallet}
          className="btnConnect"
          style={{
            cursor: "pointer",
            right: screenWidth > 800 && toggle == "-260px" ? "0px" : "260px",
          }}
        >
          Connect
        </button>
      )}
    </>
  );
};

export default ConnectButton;
