import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Sender from "./Sender";
import Receiver from "./Receiver";
import "bootstrap/dist/css/bootstrap.min.css";

const SenderReceiverControls = () => {
  const bonuses = [
    { title: "Sender Payment", value: "sender_payment" },
    { title: "Receiver Payment", value: "receiver_payment" },
  ];
  const [activeTab, setActiveTab] = useState(bonuses[0]);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const tab = params.get("tab");
    const activeTab =
      bonuses.find((bonus) => bonus.value === tab) || bonuses[0];
    setActiveTab(activeTab);
    console.log("activeTab", activeTab);
  }, [location.search]);

  function handleTabClick(value) {
    setActiveTab(value);
    navigate(`?tab=${value.value}`);
  }
  return (
    <div className="container mt-4">
      <div className="tab-container">
        {bonuses.map((x, i) => (
          <div
            key={i}
            className={`tab ${x.value === activeTab.value ? "active" : ""}`}
            onClick={() => handleTabClick(x)}
          >
            {x.title}
          </div>
        ))}
      </div>
      <div className="tab-content">
        {activeTab.value === "sender_payment" && <Sender />}
        {activeTab.value === "receiver_payment" && <Receiver />}
      </div>
    </div>
  );
};

export default SenderReceiverControls;
