import React, { useState, useCallback } from "react";
import axios from "axios";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Change from "../../Common/StringToSub";
import { Container, Row, Col } from "react-bootstrap";
import { Modal, Button } from "react-bootstrap";
import { BiFilter } from "react-icons/bi";
import { GrPowerReset } from "react-icons/gr";
import { useDispatch, useSelector } from "react-redux";
import PaginationComponent from "../../Component/Pagination/PaginationControls";
import moment from "moment";
import debounce from "lodash.debounce";
import CopyComponent from "../../Component/CopyComponent/CopyComponent";
import { IoIosEye } from "react-icons/io";
import { HiDownload } from "react-icons/hi";
import Popup from "reactjs-popup";
import {
  approveWithdrawalRequest,
  fetchAllTransaction,
  rejectWithdrawalRequest,
} from "../../Actions/adminActions";
const PendingWithDollar = () => {
  const [data, setData] = useState([]);
  const { paymentRequests } = useSelector((state) => state.admin);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [filter, setFilter] = useState("none");
  const [showModal, setShowModal] = useState(false);
  const [search, setsearch] = useState({
    source: "withdrawal",
    wallet_type: "c4u_wallet",
    status: "0",
    amount: "",
    username: "",
    search: "",
    startDate: "",
    endDate: "",
  });

  const [limitFilter, setLimitFilter] = useState(20);
  const [dateFilter, setDateFilter] = useState("default");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const fetchWithdraw = useCallback(
    debounce((params) => {
      dispatch(fetchAllTransaction(params));
    }, 500),
    [dispatch]
  );

  const toggleFilter = () => {
    if (filter === "none") {
      setFilter("flex");
    } else {
      setFilter("none");
    }
  };

  const handlePagination = (page) => {
    setCurrentPage(page);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "startDate") {
      setStartDate(value);
    } else if (name === "endDate") {
      setEndDate(value);
    } else {
      setsearch((prev) => {
        const updatedSearch = { ...prev, [name]: value };
        Object.keys(updatedSearch).forEach((key) => {
          if (updatedSearch[key] === "") {
            delete updatedSearch[key];
          }
        });
        return updatedSearch;
      });
    }
    setCurrentPage(1);
    fetchWithdraw({
      page: 1,
      limit: limitFilter,
      ...Object.fromEntries(
        Object.entries(search).filter(([_, value]) => value !== "")
      ),
      ...(startDate && endDate ? { startDate, endDate } : {}),
    });
  };
  const handleLimitChange = (e) => {
    setLimitFilter(e.target.value);
  };

  const handleDateFilterChange = (e) => {
    const value = e.target.value;
    setDateFilter(value);
    const today = moment().endOf("day");
    let start = "";
    let end = "";
    switch (value) {
      case "today":
        start = today.startOf("day").format("YYYY-MM-DD");
        end = today.format("YYYY-MM-DD");
        break;
      case "yesterday":
        start = today.subtract(1, "days").startOf("day").format("YYYY-MM-DD");
        end = today.endOf("day").format("YYYY-MM-DD");
        break;
      case "this_week":
        start = today.startOf("week").format("YYYY-MM-DD");
        end = today.endOf("week").format("YYYY-MM-DD");
        break;
      case "this_month":
        start = today.startOf("month").format("YYYY-MM-DD");
        end = today.endOf("month").format("YYYY-MM-DD");
        break;
      default:
        start = "";
        end = "";
        break;
    }
    setStartDate(start);
    setEndDate(end);
  };

  const Addfilter = () => {
    const queryParams = {
      page: currentPage,
      limit: limitFilter,
      ...Object.fromEntries(
        Object.entries(search).filter(([_, value]) => value !== "")
      ),
    };

    if (startDate) {
      queryParams.startDate = startDate;
    }
    if (endDate) {
      queryParams.endDate = endDate;
    }

    dispatch(fetchAllTransaction(queryParams));
  };

  const resetFilter = () => {
    setsearch({
      source: "withdrawal",
      wallet_type: "c4u_wallet",
      status: "0",
      amount: "",
      username: "",
      search: "",
      startDate: "",
      endDate: "",
    });
    setLimitFilter(20);
    setStartDate("");
    setEndDate("");
    setDateFilter("default");
    setCurrentPage(1);

    dispatch(
      fetchAllTransaction({
        page: 1,
        limit: 20,
        source: "withdrawal",
        wallet_type: "c4u_wallet",
        status: "0",
      })
    );
  };

  useEffect(() => {
    const queryParams = {
      page: currentPage,
      limit: 20,
      ...Object.fromEntries(
        Object.entries(search).filter(([_, value]) => value !== "")
      ),
    };
    if (startDate && endDate) {
      queryParams.startDate = startDate;
      queryParams.endDate = endDate;
    }
    fetchWithdraw(queryParams);
  }, [dispatch, currentPage, limitFilter, search, startDate, endDate]);

  useEffect(() => {
    setTotalPages(paymentRequests?.totalPages);
  }, [paymentRequests]);

  const [selectAll, setSelectAll] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [showRejectionReason, setShowRejectionReason] = useState(false);
  const [showReason, setShowReason] = useState(false);
  const [currentTxId, setCurrentTxId] = useState(null);
  const [currentApproveTxId, setCurrentApproveTxId] = useState(null);
  const [showApproveModal, setShowApproveModal] = useState(false);
  const [rejectionReason, setRejectionReason] = useState("");
  const [showApproveAllModal, setShowApproveAllModal] = useState(false);
  const [tx_Ids, setTx_Ids] = useState([]);

  const toggleSelectAll = () => {
    setSelectAll(!selectAll);
    console.log(selectAll);
    if (!selectAll) {
      setSelectedRows(paymentRequests?.data?.map((_, index) => index));
      setTx_Ids(paymentRequests?.data?.map((row) => row.tx_Id));
    } else {
      setSelectedRows([]);
      setTx_Ids([]);
    }
  };

  const toggleSelectRow = (index, tx_Id) => {
    if (selectedRows.includes(index)) {
      setSelectedRows(selectedRows.filter((rowIndex) => rowIndex !== index));
      setTx_Ids(tx_Ids.filter((rowIndex) => rowIndex !== tx_Id));
    } else {
      setSelectedRows([...selectedRows, index]);
      setTx_Ids([...tx_Ids, tx_Id]);
    }
  };

  const handleApproveAllClick = () => {
    setShowApproveAllModal(true);
  };

  const handleApproveAll = async () => {
    try {
      await dispatch(approveWithdrawalRequest(tx_Ids));
      setShowApproveAllModal(false);
      dispatch(
        fetchAllTransaction({
          page: 1,
          limit: 20,
          source: "withdrawal",
          wallet_type: "c4u_wallet",
          status: "0",
        })
      );
    } catch (e) {
      console.log(e);
    }
  };

  const handleCancelApproveAll = () => {
    setShowApproveAllModal(false);
  };

  const handleConfirmApprove = (tx_Id) => {
    setCurrentApproveTxId(tx_Id);
    setShowApproveModal(true);
  };

  const handleCancelApprove = () => {
    setShowApproveModal(false);
    setCurrentApproveTxId(null);
  };

  const handleApprove = async () => {
    try {
      await dispatch(approveWithdrawalRequest([currentApproveTxId]));
      setCurrentApproveTxId(null);
      setShowApproveModal(false);
      dispatch(
        fetchAllTransaction({
          page: 1,
          limit: 20,
          source: "withdrawal",
          wallet_type: "c4u_wallet",
          status: "0",
        })
      );
    } catch (e) {
      console.log(e);
    }
  };

  const handleRejectAll = () => {
    setShowRejectionReason(true);
  };

  const handleReject = (tx_Id) => {
    setCurrentTxId(tx_Id);
  };

  const handleSaveRejectionReason = async () => {
    try {
      await dispatch(rejectWithdrawalRequest(tx_Ids, rejectionReason));
      setShowRejectionReason(false);
      dispatch(
        fetchAllTransaction({
          page: 1,
          limit: 20,
          source: "withdrawal",
          wallet_type: "c4u_wallet",
          status: "0",
        })
      );
    } catch (e) {
      console.log(e);
    }
  };

  const handleSaveReason = async () => {
    try {
      await dispatch(rejectWithdrawalRequest([currentTxId], rejectionReason));
      setCurrentTxId(null);
      setRejectionReason("");
      dispatch(
        fetchAllTransaction({
          page: 1,
          limit: 20,
          source: "withdrawal",
          wallet_type: "c4u_wallet",
          status: "0",
        })
      );
    } catch (e) {
      console.log(e);
    }
  };
  const handleViewDetails = (row) => {
    navigate("/dashboard/withdraw_details", { state: { withdrawal: row } });
  };
  return (
    <React.Fragment>
      <Row id="filterRow">
        <span className="filterL">
          <div className="d-flex justify-content-between">
            <div onClick={() => toggleFilter()}>
              <i>
                <BiFilter />
              </i>
              Filter
            </div>
            <div className="align-content-center">
              Total Debited Amount : {paymentRequests?.debitSum}
            </div>
          </div>
        </span>

        <Row className="p-0 m-0" style={{ display: filter }}>
          <Col lg="2" md="4">
            <div>
              <input
                type="text"
                name="search"
                placeholder="Search By Name, Username"
                value={search.search}
                onChange={handleChange}
              />
            </div>
          </Col>
          <Col lg="2" md="4">
            <div>
              <input
                type="number"
                name="amount"
                placeholder="Search By Amount"
                value={search.amount}
                onChange={handleChange}
              />
            </div>
          </Col>
          <Col lg="2" md="4">
            <div>
              <input
                type="date"
                name="startDate"
                placeholder="Start Date"
                value={startDate}
                onChange={handleChange}
              />
            </div>
          </Col>
          <Col lg="2" md="4">
            <div>
              <input
                type="date"
                name="endDate"
                placeholder="End Date"
                value={endDate}
                onChange={handleChange}
              />
            </div>
          </Col>
          <Col lg="2" md="4">
            <div>
              <select
                name="dateFilter"
                value={dateFilter}
                onChange={handleDateFilterChange}
              >
                <option value="default">Default</option>
                <option value="today">Today</option>
                <option value="yesterday">Yesterday</option>
                <option value="this_week">This Week</option>
                <option value="this_month">This Month</option>
              </select>
            </div>
          </Col>
          <Col lg="2" md="4">
            <div>
              <select name="" value={limitFilter} onChange={handleLimitChange}>
                <option value="">--All--</option>
                <option value="20">20</option>
                <option value="50">50</option>
                <option value="100">100</option>
                <option value="200">200</option>
              </select>
            </div>
          </Col>
          <div>
            <button className="save filter" onClick={Addfilter}>
              Filter{" "}
              <i>
                <BiFilter />
              </i>
            </button>
            <button className="cancel reset" onClick={resetFilter}>
              Reset{" "}
              <i>
                <GrPowerReset />
              </i>
            </button>
          </div>
        </Row>
      </Row>
      <div id="directIncomeDiv">
        <div className="button-container">
          <button className="action-btn" onClick={handleApproveAllClick}>
            Approve All
          </button>
          <button className="action-btn" onClick={handleRejectAll}>
            Reject All
          </button>
        </div>
        <Modal show={showApproveAllModal} onHide={handleCancelApproveAll}>
          <Modal.Header closeButton>
            <Modal.Title>Confirm Approve All</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Are you sure you want to approve all selected transactions?
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCancelApproveAll}>
              Cancel
            </Button>
            <Button variant="primary" onClick={handleApproveAll}>
              Approve All
            </Button>
          </Modal.Footer>
        </Modal>
        {showRejectionReason && (
          <div className="rejection-reason-container">
            <button
              className="close-btn1"
              onClick={() => setShowRejectionReason(false)}
            >
              x
            </button>
            <div className="reject-container">
              <input
                type="text"
                placeholder="Enter rejection reason"
                value={rejectionReason}
                onChange={(e) => setRejectionReason(e.target.value)}
                className="rejection-reason-input"
              />
              <button className="save-btn" onClick={handleSaveRejectionReason}>
                Save
              </button>
            </div>
          </div>
        )}
        <Row id="directTable" className="text-center">
          <Col md="12" className="p-0">
            <div style={{ width: "100%", display: "flex" }}>
              <table>
                <tr>
                  {/* <th>
                    <div className="checkbox-cell">
                      <input
                        type="checkbox"
                        checked={selectAll}
                        onChange={toggleSelectAll}
                        className="checkbox-input"
                        id="selectAll"
                      />
                      <label htmlFor="selectAll" className="checkbox-label">
                        <span
                          className={`custom-checkbox ${
                            selectAll ? "checked" : ""
                          }`}
                        />
                        <span className="checkbox-text">Select All</span>
                      </label>
                    </div>
                  </th> */}
                  <th>S. No.</th>
                  <th>Name</th>
                  <th>Username</th>
                  <th>Amount</th>
                  <th>Paid Amount</th>
                  <th>Paid Amount in Progress</th>
                  <th>Account Details</th>
                  <th>Status</th>
                  <th>Date</th>
                </tr>
                {paymentRequests?.data?.map((row, index) => (
                  <tr key={index}>
                    {/* <td>
                      <div className="checkButton">
                        <input
                          type="checkbox"
                          checked={selectedRows.includes(index)}
                          onChange={() => toggleSelectRow(index, row.tx_Id)}
                          className="checkbox-input"
                          id={`row-${index}`}
                        />
                        <label
                          htmlFor={`row-${index}`}
                          className="checkbox-label1"
                        >
                          <span
                            className={`custom-checkbox ${
                              selectedRows.includes(index) ? "checked" : ""
                            }`}
                          />
                        </label>

                        <div className="button-container2">
                          <button
                            className="ViewBtn"
                            onClick={() => handleViewDetails(row)}
                          >
                            <IoIosEye />
                          </button> */}
                    {/* <button
                            className="action-btn1"
                            onClick={() => handleConfirmApprove(row.tx_Id)}
                          >
                            Approve
                          </button>
                          <button
                            className="action-btn1"
                            onClick={() => handleReject(row.tx_Id)}
                          >
                            Reject
                          </button> */}
                    {/* </div> */}

                    {/*<Modal
                          show={showApproveModal}
                          onHide={handleCancelApprove}
                        >
                          <Modal.Header closeButton>
                            <Modal.Title>Confirm Approval</Modal.Title>
                          </Modal.Header>
                          <Modal.Body>
                            <p>
                              Are you sure you want to approve this transaction?
                            </p>
                            <p>Name: {row.name}</p>
                            <p>Username: {row.username}</p>
                            <p>Amount: {parseFloat(row.amount).toFixed(2)}</p>
                          </Modal.Body>
                          <Modal.Footer>
                            <Button
                              variant="secondary"
                              onClick={handleCancelApprove}
                            >
                              Cancel
                            </Button>
                            <Button variant="primary" onClick={handleApprove}>
                              Approve
                            </Button>
                          </Modal.Footer>
                        </Modal>*/}

                    {/*currentTxId === row.tx_Id && (
                          <div className="rejection-reason-container">
                            <button
                              className="close-btn1"
                              onClick={() => setCurrentTxId(null)}
                            >
                              x
                            </button>
                            <div className="reject-container">
                              <input
                                type="text"
                                placeholder="Enter rejection reason"
                                value={rejectionReason}
                                onChange={(e) =>
                                  setRejectionReason(e.target.value)
                                }
                                className="rejection-reason-input"
                              />
                              <button
                                className="save-btn"
                                onClick={handleSaveReason}
                              >
                                Save
                              </button>
                            </div>
                          </div>
                        )*/}
                    {/* </div>
                    </td> */}
                    <td>{index + 1 + 20 * (currentPage - 1)}</td>
                    <td>{row.name}</td>
                    <td>{row.username}</td>
                    <td>{parseFloat(row.amount).toFixed(2)}</td>
                    <td>{parseFloat(row.paid_amount).toFixed(2)}</td>
                    <td>{parseFloat(row.paid_amount_in_process).toFixed(2)}</td>
                    <td>
                      {row?.account &&
                        Object.entries(row?.account)
                          .filter(
                            ([key]) =>
                              key !== "_id" &&
                              key !== "status" &&
                              key !== "chain"
                          )
                          .map(([key, value]) => (
                            <div className="keyValue">
                              <p>{key} :</p>
                              <p>{value}</p>
                              {key === "address" ? (
                                <CopyComponent value={value} />
                              ) : null}
                            </div>
                          ))}
                    </td>
                    <td>
                      {row.status === 0
                        ? "Pending"
                        : row.status === 1
                        ? "Approved"
                        : "Rejected"}
                    </td>
                    <td>
                      {moment(row?.createdAt).format("DD-MM-YYYY   &   HH:mm")}
                    </td>
                  </tr>
                ))}
              </table>
            </div>
          </Col>
        </Row>
      </div>
      <Row>
        <Col md="12">
          {
            <PaginationComponent
              currentPage={currentPage}
              totalPages={totalPages}
              onPageChange={handlePagination}
            />
          }
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default PendingWithDollar;
