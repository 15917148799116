import './EditProfile.css';
import { Container, Row, Col } from 'react-bootstrap';
import React, { useEffect, useState } from 'react';
import Two from './../../Images/two.png';
import { useLocation } from 'react-router-dom';
import { BsCheckCircle } from "react-icons/bs";
import EditProfileSection from './EditProfileSection';
import AlertMessage from '../../Component/AlertMessage/AlertMessage';
import { BiErrorCircle } from "react-icons/bi";
import apiCall from './../../API/API'; 

const EditProfile = () => {
    const location = useLocation();
    const { user } = location.state || {};
    const uid = user?.uid; 
    const username = user?.username;
    function delay(delayInms) {
        return new Promise(resolve => setTimeout(resolve, delayInms));
    }

    const [page, setPage] = useState('page1');
    const [active, setActive] = useState(true);
    const [errors, setErrors] = useState({});
    const [success, setSuccess] = useState(null);
    const [passwordData, setPasswordData] = useState({
        adminPassword: '',
        NewPassword: '',
        ConfirmPassword: '',
        username:username,
    });

    const handleChange = (event) => {
        setPasswordData({
            ...passwordData,
            [event.target.name]: event.target.value
        });
    }

    const validateForm = () => {
        let newErrors = {};
        if (!passwordData.adminPassword) {
            newErrors.password = 'Admin Password is required';
        }
        if (!passwordData.NewPassword) {
            newErrors.NewPassword = 'New Password is required';
        } else if (passwordData.NewPassword.length < 6) {
            newErrors.NewPassword = 'Password should be at least 6 characters';
        }
        if (!passwordData.ConfirmPassword) {
            newErrors.ConfirmPassword = 'Confirm Password is required';
        }
        if (passwordData.NewPassword !== passwordData.ConfirmPassword) {
            newErrors.ConfirmPassword = 'Passwords do not match';
        }
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleSubmit = async (event) => {

        event.preventDefault();
        console.log(passwordData);
        if (validateForm()) {
            const { data, error } = await apiCall("POST", `/user-password-update?username=${username}`, {passwordData}, true);
            if (data) {
                setSuccess("true");
                setPasswordData({
                    adminPassword: '',
                    NewPassword: '',
                    ConfirmPassword: '', 
                    username:username,
                });
                await delay(3000);
                setSuccess("null");
            } else {
                setSuccess("false");
                await delay(3000);
                setSuccess("null");
            }
        }
    };

    useEffect (()=> {
        if(page === 'page1'){
            setActive('true');
        }
        else {
            setActive('false');
        }
    })

    return (
        <React.Fragment>
            <Container>
                <section id='editProfile'>
                    <div id='profileImage' className='d-flex' style={{ justifyContent: 'center' }}>
                        {/* <h1 className='text-center'>Edit Profile</h1> */}
                    </div>
                    <div id='tabs' className='d-flex'>
                        <p id='infoTab' className={`${active === 'true' ? 'userActive' : 'notactive'}`} style={{ cursor: 'pointer' }} onClick={() => (setPage('page1'), setActive('true'))}>User Information</p>
                        <p id='passTab' className={`${active !== 'true' ? 'userActive' : 'notactive'}`} style={{ cursor: 'pointer' }} onClick={() => (setPage('page2'), setActive('false'))}>Change Password</p>
                    </div>
                    <section id='editProfileContent'>
                        {
                            // ----------------------Tab change code here--------------------------
                            page === 'page1' ? <EditProfileSection user={user} /> : <section>
                                <div className='form'>
                                    <Row>
                                        <Col lg='6' id='passImage'>
                                            <img src={Two} alt='' />
                                        </Col>
                                        <Col lg='6' style={{ margin: 'auto' }}>
                                            <form onSubmit={handleSubmit} className="registerForm">
                                                <div id='changePassword'>
                                                    <div>
                                                        <label>
                                                            Admin Password
                                                        </label>
                                                        <input
                                                            type='password'
                                                            name='adminPassword'
                                                            value={passwordData.adminPassword}
                                                            onChange={handleChange}
                                                        />
                                                        {errors.adminPassword && <div className="formError">{errors.adminPassword}</div>}
                                                    </div>
                                                    <div>
                                                        <label>
                                                            User New Password
                                                        </label>
                                                        <input
                                                            type='password'
                                                            name='NewPassword'
                                                            value={passwordData.NewPassword}
                                                            onChange={handleChange}
                                                        />
                                                        {errors.NewPassword && <div className="formError">{errors.NewPassword}</div>}
                                                    </div>
                                                    <div>
                                                        <label>
                                                            User Confirm Password
                                                        </label>
                                                        <input
                                                            type='password'
                                                            name='ConfirmPassword'
                                                            value={passwordData.ConfirmPassword}
                                                            onChange={handleChange}
                                                        />
                                                        {errors.ConfirmPassword && <div className="formError">{errors.ConfirmPassword}</div>}
                                                    </div>
                                                    <button type='submit' className='save'>Update</button>
                                                    <a onClick={() => setPasswordData({
                                                        adminPassword: '',
                                                        NewPassword: '',
                                                        ConfirmPassword: '',
                                                        username:username,
                                                    })} className='cancel'>Cancel</a>
                                                </div>
                                            </form>
                                        </Col>
                                    </Row>
                                </div>
                            </section>}
                    </section>
                </section>
                {success === 'true' ? <AlertMessage
                    msg="Request success"
                    icon={<BsCheckCircle />}
                /> : success === 'false' ? <AlertMessage
                    msg="Request Failed"
                    bgcolor="white"
                    color="red"
                    hrcolor="red"
                    icon={<BiErrorCircle />}
                /> : ''}
            </Container>
        </React.Fragment>
    )
}

export default EditProfile;
