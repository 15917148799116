import BUSD_ABI from "../Contracts/BUSD_ABI.json";
import contractABI from "../Contracts/contract_ABI.json";
const arr = {
  contract: "0xDA3fc5659f4306104FE8401b50E8A9925B8Cf50A",
  contractABI: contractABI,
  BUSD: "0x7263f0797012aF8C6a0Aa9C79Ed86B8354F4E295",
  BUSD_ABI: BUSD_ABI,
};

export const ContractDetails = arr;

export default ContractDetails;
