import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import ApprovedReward from './ApprovedReward';
import PendingReward from './PendingReward';
import RejectedReward from './RejectedReward';
import 'bootstrap/dist/css/bootstrap.min.css';
import './With.css';

const RewardControls = () => {
    const bonuses = [
        { title: "Pending Reward", value: "pending_reward" },
        { title: "Approved Reward", value: "approved_reward" },
        { title: "Rejected Reward", value: "rejected_reward" },
    ];
    const [activeTab, setActiveTab] = useState(bonuses[0]);
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const tab = params.get('tab');
        const activeTab = bonuses.find(bonus => bonus.value === tab) || bonuses[0];
        setActiveTab(activeTab);
        console.log("activeTab", activeTab)
    }, [location.search]);

    function handleTabClick(value) {
        setActiveTab(value);
        navigate(`?tab=${value.value}`);
    }
  return (
    <div className="container mt-4">
    <div className="tab-container">
      {bonuses.map((x, i) => (
        <div
          key={i}
          className={`tab ${x.value === activeTab.value ? "active" : ""}`}
          onClick={() => handleTabClick(x)}
        >
          {x.title}
        </div>
      ))}
    </div>
    <div className="tab-content">
      {activeTab.value === 'pending_reward' && <PendingReward />}
      {activeTab.value === 'approved_reward' && <ApprovedReward />}
      {activeTab.value === 'rejected_reward' && <RejectedReward />}
    {/*
    bonuses.map((x,i)=>{
        return <div style={{background: x?.value === activeTab.value ? "green" :"red" }} onClick={()=>handleTabClick(x)}>
        {x.title}
        </div>
        })
    */}
    </div>
  </div>
    
  )
}

export default RewardControls
