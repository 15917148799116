import React, { useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import axios from "axios";
import "./FileUpload.css";
import { FiUploadCloud } from "react-icons/fi";
import { MdOutlineModeEdit } from "react-icons/md";
import { RiCloseCircleLine } from "react-icons/ri";
import { Container, Row, Col } from "react-bootstrap";
import imageCompression from "browser-image-compression";
import { useNavigate } from "react-router-dom";
import apiCall from "../../API/API";
import moment from "moment";
import { HiDownload } from "react-icons/hi";
import Popup from "reactjs-popup";
import { toast, ToastContainer } from "react-toastify";
import { Modal, Button } from "react-bootstrap";
import { FaExpandAlt } from "react-icons/fa";
import { FaTrashAlt } from "react-icons/fa";
const FileUpload = () => {
  const [files, setFiles] = useState([]);
  const [title, setTitle] = useState();
  const [description, setDescription] = useState("");
  const [uploading, setUploading] = useState(false);
  const [error, setError] = useState(null);
  const [previews, setPreviews] = useState([]);
  const [newsData, setNewsData] = useState([]);
  const [selectedDescription, setSelectedDescription] = useState(null);
  const navigate = useNavigate();
  const onDrop = async (acceptedFiles) => {
    // Compress images
    const compressedFiles = await Promise.all(
      acceptedFiles.map(async (file) => {
        const options = {
          maxSizeMB: 1, // Maximum file size in MB
          maxWidthOrHeight: 1024, // Maximum width or height in pixels
          useWebWorker: true,
        };
        try {
          return await imageCompression(file, options);
        } catch (err) {
          console.error("Error compressing image:", err);
          return file;
        }
      })
    );

    setFiles(compressedFiles);
    setPreviews(compressedFiles.map((file) => URL.createObjectURL(file)));
  };

  const removeImage = (index) => {
    const newFiles = [...files];
    const newPreviews = [...previews];
    newFiles.splice(index, 1);
    newPreviews.splice(index, 1);
    setFiles(newFiles);
    setPreviews(newPreviews);
  };

  const uploadFiles = async () => {
    console.log("title", title);
    console.log("description", description);

    if (title?.length > 0 && description?.length > 0) {
      setUploading(true);
      setError(null);

      const formData = new FormData();
      files.forEach((file) => {
        formData.append("image", file);
      });

      formData.append("title", title);
      formData.append("description", description);

      try {
        const response = await apiCall("POST", "/add-news", formData);
        if (response?.error) {
          toast.error(response.error.message || "An error occurred");
        } else {
          setFiles([]);
          setPreviews([]);
          setTitle("");
          setDescription("");
          toast.success(
            response.data?.message || "Files uploaded successfully"
          );
          fetchNews();
        }
      } catch (error) {
        console.error("Upload error:", error);
        setError("Failed to upload files. Please try again.");
      } finally {
        setUploading(false);
      }
    } else {
      toast.error("Invalid Data");
    }
  };

  const handleShowModal = (description) => {
    setSelectedDescription(description);
  };

  const handleCloseModal = () => {
    setSelectedDescription(null);
  };

  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  const fetchNews = async () => {
    try {
      const { data, error } = await apiCall("GET", "/get-all-news");
      if (error) {
        toast.error(error.message || "An error occurred");
      } else {
        setNewsData(data);
      }
    } catch (error) {
      toast.error(error.message || "An error occurred");
    }
  };

  useEffect(() => {
    fetchNews();
  }, []);

  const handleDelete = async (id) => {
    // console.log("id", id);
    const { data, error } = await apiCall("POST", `/delete-news`, { id });
    if (error) {
      toast.error(error.message || "An error occurred");
    } else {
      toast.success(data.message || "News deleted successfully");
      fetchNews();
    }
  };

  return (
    <React.Fragment>
      <Container style={{ padding: "0px" }}>
        <div id="directIncome">
          <Row className="mt-1">
            <Col md="12" className="mb-6 mb-md-0">
              <div className="input1">
                <label>Title</label>
                <input
                  type="text"
                  placeholder="Enter Title"
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                />
                {/* <i id="myProfileInputFieldIcon">
                  <MdOutlineModeEdit />
                </i> */}
              </div>
            </Col>
            <Col md="12" className="mt-4">
              <div className="input1">
                <label>Description</label>
                <input
                  type="text"
                  placeholder="Enter Description"
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                />
                {/* <i id="myProfileInputFieldIcon">
                  <MdOutlineModeEdit />
                </i> */}
              </div>
            </Col>
          </Row>
          <div className="file-upload-container">
            <div {...getRootProps({ className: "dropzone" })}>
              <input {...getInputProps()} />
              <p>Drop your files here</p>
            </div>
            <button
              className="upload-button"
              onClick={uploadFiles}
              disabled={uploading || files.length === 0}
            >
              <i>
                <FiUploadCloud />
              </i>
              {uploading ? "Uploading..." : "Upload Files"}
            </button>
            {error && <p className="error-message">{error}</p>}
            <div className="previews">
              {previews.map((preview, index) => (
                <div key={index} className="preview">
                  <img src={preview} alt={`Preview ${index}`} />
                  <button
                    className="remove-button"
                    onClick={() => removeImage(index)}
                  >
                    <RiCloseCircleLine />
                  </button>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div id="directIncomeDiv">
          <Row id="directTableRow" className="text-center">
            <Col md="12" className="p-0">
              <div style={{ width: "100%" }}>
                <table>
                  <tr>
                    <th>S No.</th>
                    <th>Title</th>
                    <th>Description</th>
                    <th>Image</th>
                    <th>Date</th>
                    <th>Delete News</th>
                    {/* <th>Set Default</th> */}
                  </tr>
                  {newsData?.data?.map((x, i) => {
                    return (
                      <tr>
                        <td className="text-center">
                          {i + 1 /*+(limitFilter*(currentPage-1))*/}
                        </td>
                        <td className="text-center">{x?.title}</td>
                        <td className="text-center" id="tableDes">
                          {x?.description.length > 50
                            ? `${x?.description.substring(0, 50)}...`
                            : x?.description}
                          {x?.description.length > 50 && (
                            <button
                              className="read-more-btn"
                              onClick={() => handleShowModal(x?.description)}
                            >
                              Read More
                            </button>
                          )}
                        </td>
                        <td>
                          {" "}
                          <img
                            src={x?.image}
                            alt="Document"
                            style={{
                              maxWidth: "100px",
                              maxHeight: "100px",
                            }}
                          />
                          <Popup
                            trigger={
                              <button className="block">
                                <FaExpandAlt />
                              </button>
                            }
                            modal
                            closeOnDocumentClick
                          >
                            {(close) => (
                              <div className="popup-content">
                                <button className="close-btn" onClick={close}>
                                  &times;
                                </button>
                                <img
                                  src={x?.image}
                                  alt="news"
                                  className="popImg"
                                />
                                {/* <button
                                  className="download-btn"
                                  onClick={() => {
                                    const link = document.createElement("a");
                                    link.href = x?.image;
                                    link.setAttribute("download", "news.jpg"); // Setting download attribute
                                    document.body.appendChild(link); // Append link to the body
                                    link.click(); // Trigger click to start download
                                    document.body.removeChild(link); // Remove link after download
                                  }}
                                >
                                  <HiDownload />
                                </button> */}
                              </div>
                            )}
                          </Popup>
                        </td>
                        <td>
                          {moment(x?.createdAt).format(
                            "DD-MM-YYYY   &   HH:mm"
                          )}
                        </td>
                        <td className="text-center">
                          <button
                            className="bin-btn"
                            onClick={() => handleDelete(x?.id)}
                          >
                            <FaTrashAlt />
                          </button>
                        </td>
                        {/* <td className="text-center">
                          <button
                              className={`block-btn ${
                                defaultUpiId === x?.upiId ? "default-btn" : ""
                              }`}
                              onClick={() => handleDefault(x?.upiId)}
                              disabled={defaultUpiId === x?.upiId}
                            >
                              {defaultUpiId === x?.upiId
                                ? "Default"
                                : "Set Default"}
                            </button>
                        </td> */}
                      </tr>
                    );
                  })}
                </table>
              </div>
            </Col>
          </Row>
          <Modal show={!!selectedDescription} onHide={handleCloseModal}>
            <Modal.Header closeButton>
              <Modal.Title>Full Description</Modal.Title>
            </Modal.Header>
            <Modal.Body>{selectedDescription}</Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleCloseModal}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
        <ToastContainer className="toast-container" />
      </Container>
    </React.Fragment>
  );
};

export default FileUpload;
