import React, { useState, useEffect, useRef, useCallback } from "react";
import "./With.css";
import { Container, Row, Col } from "react-bootstrap";
import { BiFilter } from "react-icons/bi";
import { GrPowerReset } from "react-icons/gr";
import { useNavigate } from "react-router-dom";
import {
  approveRewardRequest,
  rejectRewardRequest,
  fetchAllRewards,
} from "../../Actions/adminActions";
import { useDispatch, useSelector } from "react-redux";
import PaginationComponent from "../../Component/Pagination/PaginationControls";
import moment from "moment";
import debounce from "lodash.debounce";

const PendingRewards = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { rewards } = useSelector((state) => state.admin);
  const [filter, setFilter] = useState("none");
  const [search, setSearch] = useState({
    username: "",
    search: "",
    startDate: "",
    endDate: "",
    status: "1",
    rewardItem: "",
    paid: "0",
  });
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [limitFilter, setLimitFilter] = useState(20);
  const [dateFilter, setDateFilter] = useState("default");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  // Debounced function to fetch rewards
  const fetchRewards = useCallback(
    debounce((params) => {
      dispatch(fetchAllRewards(params));
    }, 500),
    [dispatch]
  );

  const toggleFilter = () => {
    if (filter === "none") {
      setFilter("flex");
    } else {
      setFilter("none");
    }
  };
  const handlePagination = (page) => {
    setCurrentPage(page);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "startDate") {
      setStartDate(value);
    } else if (name === "endDate") {
      setEndDate(value);
    } else {
      setSearch((prev) => {
        const updatedSearch = { ...prev, [name]: value };
        Object.keys(updatedSearch).forEach((key) => {
          if (updatedSearch[key] === "") {
            delete updatedSearch[key];
          }
        });
        return updatedSearch;
      });
    }
    setCurrentPage(1);
    fetchRewards({
      page: 1,
      limit: limitFilter,
      ...Object.fromEntries(
        Object.entries(search).filter(([_, value]) => value !== "")
      ),
      ...(startDate && endDate ? { startDate, endDate } : {}),
    });
  };

  const handleLimitChange = (e) => {
    setLimitFilter(e.target.value);
  };

  const handleDateFilterChange = (e) => {
    const value = e.target.value;
    setDateFilter(value);
    const today = moment().endOf("day");
    let start = "";
    let end = "";
    switch (value) {
      case "today":
        start = today.startOf("day").format("YYYY-MM-DD");
        end = today.format("YYYY-MM-DD");
        break;
      case "yesterday":
        start = today.subtract(1, "days").startOf("day").format("YYYY-MM-DD");
        end = today.endOf("day").format("YYYY-MM-DD");
        break;
      case "this_week":
        start = today.startOf("week").format("YYYY-MM-DD");
        end = today.endOf("week").format("YYYY-MM-DD");
        break;
      case "this_month":
        start = today.startOf("month").format("YYYY-MM-DD");
        end = today.endOf("month").format("YYYY-MM-DD");
        break;
      default:
        start = "";
        end = "";
        break;
    }
    setStartDate(start);
    setEndDate(end);
  };

  const addFilter = () => {
    const queryParams = {
      page: currentPage,
      limit: limitFilter,
      ...Object.fromEntries(
        Object.entries(search).filter(([_, value]) => value !== "")
      ),
    };

    if (startDate) {
      queryParams.startDate = startDate;
    }
    if (endDate) {
      queryParams.endDate = endDate;
    }

    dispatch(fetchAllRewards(queryParams));
  };

  const resetFilter = () => {
    setSearch({
      username: "",
      search: "",
      startDate: "",
      endDate: "",
      rewardItem: "",
      status: "1",
      paid: "0",
    });
    setLimitFilter(20);
    setStartDate("");
    setEndDate("");
    setDateFilter("default");
    setCurrentPage(1);

    dispatch(
      fetchAllRewards({
        page: 1,
        limit: 20,
        status: "1",
        paid: "0",
      })
    );
  };

  useEffect(() => {
    const queryParams = {
      page: currentPage,
      limit: 20,
      ...Object.fromEntries(
        Object.entries(search).filter(([_, value]) => value !== "")
      ),
    };
    if (startDate && endDate) {
      queryParams.startDate = startDate;
      queryParams.endDate = endDate;
    }
    fetchRewards(queryParams);
  }, [
    dispatch,
    currentPage,
    limitFilter,
    search,
    startDate,
    endDate,
    fetchRewards,
  ]);

  useEffect(() => {
    setTotalPages(rewards?.totalPages);
  }, [rewards]);

  const [selectAll, setSelectAll] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [showRejectionReason, setShowRejectionReason] = useState(false);
  const [rejectionReason, setRejectionReason] = useState("");
  const [showReason, setShowReason] = useState(false);
  const [currentTxId, setCurrentTxId] = useState(null);
  const [Id, setId] = useState([]);
  const [rewardItemsMap, setRewardItemsMap] = useState({});
  const [itemsMap, setItemsMap] = useState({});

  const toggleSelectAll = () => {
    setSelectAll(!selectAll);
    console.log(selectAll);
    if (!selectAll) {
      setSelectedRows(rewards?.data?.map((_, index) => index));
      setId(rewards?.data?.map((row) => row.Id));
    } else {
      setSelectedRows([]);
      setId([]);
    }
  };

  const toggleSelectRow = (index, tx_Id, item, rewardItem) => {
    setSelectedRows((prevSelectedRows) => {
      const updatedRows = prevSelectedRows.includes(index)
        ? prevSelectedRows.filter((rowIndex) => rowIndex !== index)
        : [...prevSelectedRows, index];
      return updatedRows;
    });

    setId((prevIds) => {
      const updatedIds = prevIds.includes(tx_Id)
        ? prevIds.filter((id) => id !== tx_Id)
        : [...prevIds, tx_Id];
      return updatedIds;
    });

    setItemsMap((prevMap) => ({
      ...prevMap,
      [tx_Id]: item,
    }));

    setRewardItemsMap((prevMap) => ({
      ...prevMap,
      [tx_Id]: rewardItem,
    }));
  };

  const handleApproveAll = async () => {
    try {
      const itemsArray = Id.map((id) => itemsMap[id]);
      const rewardsItemsArray = Id.map((id) => rewardItemsMap[id]);
      console.log("selected tx id, reward ", Id, itemsArray, rewardsItemsArray);
      await dispatch(approveRewardRequest(Id, itemsArray, rewardsItemsArray));
      dispatch(
        fetchAllRewards({
          page: 1,
          limit: 20,
          status: "1",
          paid: "0",
        })
      );
    } catch (e) {
      console.log(e);
    }
  };

  const handleApprove = async (tx_Id, item, rewardItem) => {
    try {
      console.log("tx_Id", tx_Id);
      console.log("item", item);
      console.log("rewardItem", rewardItem);
      await dispatch(approveRewardRequest([tx_Id], item, rewardItem));
      dispatch(
        fetchAllRewards({
          page: 1,
          limit: 20,
          status: "1",
          paid: "0",
        })
      );
    } catch (e) {
      console.log(e);
    }
  };

  const handleRejectAll = () => {
    setShowRejectionReason(true);
  };

  const handleReject = (Id) => {
    setCurrentTxId(Id);
  };

  const handleSaveRejectionReason = async () => {
    try {
      const itemsArray = Id.map((id) => itemsMap[id]);
      const rewardsItemsArray = Id.map((id) => rewardItemsMap[id]);
      console.log("selected tx id", Id);
      await dispatch(
        rejectRewardRequest(Id, itemsArray, rewardsItemsArray, rejectionReason)
      );
      setShowRejectionReason(false);
      dispatch(
        fetchAllRewards({
          page: 1,
          limit: 20,
          status: "1",
          paid: "0",
        })
      );
    } catch (e) {
      console.log(e);
    }
  };

  const handleSaveReason = async (item, rewardItem) => {
    try {
      console.log("selected id", currentTxId, rewardItem);
      console.log("item", item);
      await dispatch(
        rejectRewardRequest([currentTxId], item, rewardItem, rejectionReason)
      );
      setCurrentTxId(null);
      setRejectionReason("");
      dispatch(
        fetchAllRewards({
          page: 1,
          limit: 20,
          status: "1",
          paid: "0",
        })
      );
    } catch (e) {
      console.log(e);
    }
  };
  return (
    <React.Fragment>
      <Container>
        <Row id="filterRow">
          <span id="filterLogo1" onClick={toggleFilter}>
            <i>
              <BiFilter />
            </i>
            Filter
          </span>

          <Row className="p-0 m-0" style={{ display: filter }}>
            <Col lg="2" md="4">
              <div>
                <input
                  type="text"
                  name="username"
                  placeholder="Search By Username"
                  value={search.username}
                  onChange={handleChange}
                />
              </div>
            </Col>
            <Col lg="2" md="4">
              <div>
                <input
                  type="text"
                  name="search"
                  placeholder="Search By Rank Name"
                  value={search.search}
                  onChange={handleChange}
                />
              </div>
            </Col>
            <Col lg="2" md="4">
              <div>
                <input
                  type="date"
                  name="startDate"
                  placeholder="Start Date"
                  value={startDate}
                  onChange={handleChange}
                />
              </div>
            </Col>
            <Col lg="2" md="4">
              <div>
                <input
                  type="date"
                  name="endDate"
                  placeholder="End Date"
                  value={endDate}
                  onChange={handleChange}
                />
              </div>
            </Col>
            <Col lg="2" md="4">
              <div>
                <select
                  name="dateFilter"
                  value={dateFilter}
                  onChange={handleDateFilterChange}
                >
                  <option value="default">Default</option>
                  <option value="today">Today</option>
                  <option value="yesterday">Yesterday</option>
                  <option value="this_week">This Week</option>
                  <option value="this_month">This Month</option>
                </select>
              </div>
            </Col>
            <Col lg="2" md="4">
              <div>
                <select value={limitFilter} onChange={handleLimitChange}>
                  <option value="20">20</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                  <option value="200">200</option>
                </select>
              </div>
            </Col>
            <div>
              <button className="save filter" onClick={addFilter}>
                Filter{" "}
                <i>
                  <BiFilter />
                </i>
              </button>
              <button className="cancel reset" onClick={resetFilter}>
                Reset{" "}
                <i>
                  <GrPowerReset />
                </i>
              </button>
            </div>
          </Row>
        </Row>
        <div id="directIncomeDiv">
          <div className="button-container">
            <button className="action-btn" onClick={handleApproveAll}>
              Approve All
            </button>
            <button className="action-btn" onClick={handleRejectAll}>
              Reject All
            </button>
          </div>
          {showRejectionReason && (
            <div className="rejection-reason-container">
              <button
                className="close-btn1"
                onClick={() => setShowRejectionReason(false)}
              >
                x
              </button>
              <div className="reject-container">
                <input
                  type="text"
                  placeholder="Enter rejection reason"
                  value={rejectionReason}
                  onChange={(e) => setRejectionReason(e.target.value)}
                  className="rejection-reason-input"
                />
                <button
                  className="save-btn"
                  onClick={handleSaveRejectionReason}
                >
                  Save
                </button>
              </div>
            </div>
          )}
          <Row id="directTableRow">
            <Col md="12" className="p-0">
              <div style={{ width: "100%", display: "flex" }}>
                <table>
                  <tr>
                    <th>
                      <div className="checkbox-cell">
                        <input
                          type="checkbox"
                          checked={selectAll}
                          onChange={toggleSelectAll}
                          className="checkbox-input"
                          id="selectAll"
                        />
                        <label htmlFor="selectAll" className="checkbox-label">
                          <span
                            className={`custom-checkbox ${
                              selectAll ? "checked" : ""
                            }`}
                          />
                          <span className="checkbox-text">Select All</span>
                        </label>
                      </div>
                    </th>
                    <th>S No.</th>
                    <th>Username</th>
                    <th>Rank Name</th>
                    <th>Reward</th>
                    <th>Achieved Date & Time</th>
                    <th>Reward Status</th>
                  </tr>

                  {rewards?.data?.map((x, i) => (
                    <tr key={x._id}>
                      <td>
                        <div className="checkButton">
                          <input
                            type="checkbox"
                            checked={selectedRows.includes(i)}
                            onChange={() =>
                              toggleSelectRow(i, x.Id, x.item, x.rewardItem)
                            }
                            className="checkbox-input"
                            id={`row-${i}`}
                          />
                          <label
                            htmlFor={`row-${i}`}
                            className="checkbox-label1"
                          >
                            <span
                              className={`custom-checkbox ${
                                selectedRows.includes(i) ? "checked" : ""
                              }`}
                            />
                          </label>
                          <div className="button-container1">
                            <button
                              className="action-btn1"
                              onClick={() =>
                                handleApprove(x.Id, x.item, x.rewardItem)
                              }
                            >
                              ✓
                            </button>
                            <button
                              className="action-btn1"
                              onClick={() => handleReject(x.Id)}
                            >
                              ✗
                            </button>
                          </div>
                          {currentTxId === x.Id && (
                            <div className="rejection-reason-container">
                              <button
                                className="close-btn1"
                                onClick={() => setCurrentTxId(null)}
                              >
                                x
                              </button>
                              <div className="reject-container">
                                <input
                                  type="text"
                                  placeholder="Enter rejection reason"
                                  value={rejectionReason}
                                  onChange={(e) =>
                                    setRejectionReason(e.target.value)
                                  }
                                  className="rejection-reason-input"
                                />
                                <button
                                  className="save-btn"
                                  onClick={() =>
                                    handleSaveReason(x.item, x.rewardItem)
                                  }
                                >
                                  Save
                                </button>
                              </div>
                            </div>
                          )}
                        </div>
                      </td>
                      <td>{i + 1 + limitFilter * (currentPage - 1)}</td>
                      <td>{x.username}</td>
                      <td>{x.rankName}</td>
                      <td>{x.rewardItem}</td>
                      <td>
                        {moment(x?.createdAt).format("MM-DD-YYYY   &   HH:mm")}
                      </td>
                      <td>{x.status === 1 ? "Achieved" : "Pending"}</td>
                    </tr>
                  ))}
                </table>
              </div>
            </Col>
          </Row>
        </div>
        <Row>
          <Col md="12">
            {
              <PaginationComponent
                currentPage={currentPage}
                totalPages={totalPages}
                onPageChange={handlePagination}
              />
            }
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
};

export default PendingRewards;
