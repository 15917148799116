import React, { useState, useEffect } from "react";
import { Container, Row, Col, Card } from "react-bootstrap";
import apiCall from "./../../API/API";
import PhoneInput from "react-phone-number-input";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const EditProfileSection = ({ user }) => {
  const [file, setFile] = useState(user?.profile_pic || "");
  const [name, setName] = useState(user?.name || "");
  const [email, setEmail] = useState(user?.email || "");
  const [phone, setPhone] = useState(user?.mobile || "");
  const [userid, setUserid] = useState(user?.uid || "");
  const [success, setSuccess] = useState("null");
  function delay(delayInms) {
    return new Promise((resolve) => setTimeout(resolve, delayInms));
  }
  const [errors, setErrors] = useState({});
  console.log("user", user);


  const validateForm = () => {
    let newErrors = {};
    if (!userid) {
      newErrors.userid = "User id is required";
    }
    if (!name) {
      newErrors.name = "Name is required";
    }
    if (!email) {
      newErrors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      newErrors.email = "Email is not valid";
    }
    if (!phone) {
      newErrors.phone = "Mobile number is required";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };
  // const GetData = async () => {
  //   try {
  //     console.log("token", localStorage.getItem("AuthToken"));
  //     const token = localStorage.getItem("AuthToken");
  //     const response = await fetch("http://192.168.1.16:3000/getprofile", {
  //       method: "GET",
  //       headers: {
  //         "Content-Type": "application/json",
  //         Accept: "application/json",
  //         Authorization: token,
  //       },
  //     });
  //     var data = await response.json();
  //     console.log(data);
  //     if (data.status) {
  //       setName(data?.result?.name);
  //       setEmail(data?.result?.email);
  //       setFile(data?.result?.profile_pic);
  //       setPhone(data?.result?.phone);
  //       setUserid(data?.result?.user_Id);
  //     }
  //   } catch (e) {
  //     console.log(e);
  //   }
  // };
  const handleSubmit = async (event) => {
    event.preventDefault();
    if (validateForm()) {
      const { data, error } = await apiCall(
        "POST",
        `/user-profile-update?uid=${userid}`,
        { name, email, mobile: phone },
        true
      );
      console.log(data, "....");
      if (data) {
        setSuccess("true");
        await delay(3000);
        setSuccess("null");
      } else {
        setSuccess("false");
        await delay(3000);
        setSuccess("null");
      }
    }
  };

  return (
    <>
      <React.Fragment>
        <section className="formItems">
          <div className="uploadImage">
            <img src={file} alt="logo.png" />
            <div className="chooseFile">
              <label>
                <span>Upload Profile</span>
                <input
                  type="file"
                  onChange={(e) => (
                    setFile(URL.createObjectURL(e.target.files[0])),
                    console.log({ file })
                  )}
                />
              </label>
            </div>
          </div>
          <div className="form">
            <Row>
              <Col lg="2"></Col>
              <Col lg="8">
                <form onSubmit={handleSubmit} className="registerForm">
                  <div>
                    <label>User Id</label>
                    <input type="number" value={userid} readOnly />
                  </div>
                  <div>
                    <label>Full Name</label>
                    <input
                      type="text"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                    />
                    {errors.name =="" && (
                      <div className="formError">{errors.name}</div>
                    )}
                  </div>
                  <div>
                    <label>Email Address</label>
                    <input
                      type="email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                    {errors.email=="" && (
                      <div className="formError">{errors.email}</div>
                    )}
                  </div>
                  <div>
                    <label>Contact Number</label>

                  <PhoneInput
  id="phoneInput"
  international
  countryCallingCodeEditable={false}
  defaultCountry="IN"
  value={phone}
  onChange={(value) => setPhone(value)} // Corrected line
/>
{errors.phone && (
  <div className="formError">{errors.phone}</div>
)}

                  </div>
                  <button onClick={handleSubmit} className="save">
                    Save
                  </button>
                  <a className="cancel">Cancel</a>
                </form>
              </Col>
            </Row>
          </div>
        </section>
        <ToastContainer className="toast-container" />
      </React.Fragment>
    </>
  );
};

export default EditProfileSection;
