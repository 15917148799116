import React, { useState, useCallback } from "react";
// import "./With.css";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import Change from "../../Common/StringToSub";
import { BiFilter } from "react-icons/bi";
import { GrPowerReset } from "react-icons/gr";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchSender,
  fetchReceiver,
  assignHelpRequest,
  fetchAssignedHelp,
} from "../../Actions/adminActions";
import Popup from "reactjs-popup";
import PaginationComponent from "../../Component/Pagination/PaginationControls";
import moment from "moment";
import debounce from "lodash.debounce";
import { HiDownload } from "react-icons/hi";
const PaymentSendReceive = () => {
  const [data, setData] = useState([]);
  const { senderPayment } = useSelector((state) => state.admin);
  const { receiverPayment } = useSelector((state) => state.admin);
  const { assignedPayment } = useSelector((state) => state.admin);
  const dispatch = useDispatch();
  const [filter, setFilter] = useState("none");
  const [receiverFilter, setReceiverFilter] = useState("none");
  const [assignedFilter, setAssignedFilter] = useState("none");
  const navigate = useNavigate();
  const [search, setsearch] = useState({
    sender_paid_amount: "",
    username: "",
    search: "",
    startDate: "",
    endDate: "",
  });
  const [receiverSearch, setReceiverSearch] = useState({
    amount: "",
    username: "",
    search: "",
    startDate: "",
    endDate: "",
  });
  const [assignedSearch, setAssignedSearch] = useState({
    amount: "",
    username: "",
    search: "",
    startDate: "",
    endDate: "",
  });
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [limitFilter, setLimitFilter] = useState(10);
  const [dateFilter, setDateFilter] = useState("default");

  const [receiverCurrentPage, setReceiverCurrentPage] = useState(1);
  const [receiverTotalPages, setReceiverTotalPages] = useState(1);
  const [receiverLimitFilter, setReceiverLimitFilter] = useState(10);
  const [receiverDateFilter, setReceiverDateFilter] = useState("default");

  const [assignedCurrentPage, setAssignedCurrentPage] = useState(1);
  const [assignedTotalPages, setAssignedTotalPages] = useState(1);
  const [assignedLimitFilter, setAssignedLimitFilter] = useState(10);
  const [assignedDateFilter, setAssignedDateFilter] = useState("default");

  const fetchSenderList = useCallback(
    debounce((params) => {
      dispatch(fetchSender(params));
    }, 500),
    [dispatch]
  );

  const fetchReceiverList = useCallback(
    debounce((params) => {
      dispatch(fetchReceiver(params));
    }, 500),
    [dispatch]
  );

  const fetchAssignedList = useCallback(
    debounce((params) => {
      dispatch(fetchAssignedHelp(params));
    }, 500),
    [dispatch]
  );

  const toggleFilter = () => {
    if (filter === "none") {
      setFilter("flex");
    } else {
      setFilter("none");
    }
  };

  const receiverToggleFilter = () => {
    if (receiverFilter === "none") {
      setReceiverFilter("flex");
    } else {
      setReceiverFilter("none");
    }
  };

  const assignedToggleFilter = () => {
    if (assignedFilter === "none") {
      setAssignedFilter("flex");
    } else {
      setAssignedFilter("none");
    }
  };

  const handlePagination = (page) => {
    setCurrentPage(page);
  };

  const handleReceiverPagination = (page) => {
    setReceiverCurrentPage(page);
  };

  const handleAssignedPagination = (page) => {
    setAssignedCurrentPage(page);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setsearch((prev) => {
      const updatedSearch = { ...prev, [name]: value };
      Object.keys(updatedSearch).forEach((key) => {
        if (updatedSearch[key] === "" || updatedSearch[key] === 0) {
          delete updatedSearch[key];
        }
      });
      return updatedSearch;
    });
    setCurrentPage(1);
    fetchSenderList({
      page: 1,
      limit: limitFilter,
      ...Object.fromEntries(
        Object.entries(search).filter(
          ([_, value]) => value !== "" && value !== 0
        )
      ),
    });
  };

  const handleReceiverChange = (e) => {
    const { name, value } = e.target;
    setReceiverSearch((prev) => {
      const updatedSearch = { ...prev, [name]: value };
      Object.keys(updatedSearch).forEach((key) => {
        if (updatedSearch[key] === "" || updatedSearch[key] === 0) {
          delete updatedSearch[key];
        }
      });
      return updatedSearch;
    });
    setReceiverCurrentPage(1);
    fetchReceiverList({
      page: 1,
      limit: limitFilter,
      ...Object.fromEntries(
        Object.entries(receiverSearch).filter(
          ([_, value]) => value !== "" && value !== 0
        )
      ),
    });
  };

  const handleAssignedChange = (e) => {
    const { name, value } = e.target;
    setAssignedSearch((prev) => {
      const updatedSearch = { ...prev, [name]: value };
      Object.keys(updatedSearch).forEach((key) => {
        if (updatedSearch[key] === "" || updatedSearch[key] === 0) {
          delete updatedSearch[key];
        }
      });
      return updatedSearch;
    });
    setAssignedCurrentPage(1);
    fetchAssignedList({
      page: 1,
      limit: limitFilter,
      ...Object.fromEntries(
        Object.entries(assignedSearch).filter(
          ([_, value]) => value !== "" && value !== 0
        )
      ),
    });
  };

  const handleLimitChange = (e) => {
    setLimitFilter(e.target.value);
  };

  const handleReceiverLimitChange = (e) => {
    setReceiverLimitFilter(e.target.value);
  };

  const handleAssignedLimitChange = (e) => {
    setAssignedLimitFilter(e.target.value);
  };

  const handleDateFilterChange = (e) => {
    const value = e.target.value;
    setDateFilter(value);
    const today = moment().endOf("day");
    let startDate = "";
    let endDate = "";
    switch (value) {
      case "today":
        startDate = today.startOf("day").format("YYYY-MM-DD");
        endDate = today.format("YYYY-MM-DD");
        break;
      case "yesterday":
        startDate = today
          .subtract(1, "days")
          .startOf("day")
          .format("YYYY-MM-DD");
        endDate = today.endOf("day").format("YYYY-MM-DD");
        break;
      case "this_week":
        startDate = today.startOf("week").format("YYYY-MM-DD");
        endDate = today.endOf("week").format("YYYY-MM-DD");
        break;
      case "this_month":
        startDate = today.startOf("month").format("YYYY-MM-DD");
        endDate = today.endOf("month").format("YYYY-MM-DD");
        break;
      default:
        startDate = "";
        endDate = "";
        break;
    }
    setsearch((prev) => ({
      ...prev,
      startDate,
      endDate,
    }));
  };

  const handleReceiverDateFilterChange = (e) => {
    const value = e.target.value;
    setReceiverDateFilter(value);
    const today = moment().endOf("day");
    let startDate = "";
    let endDate = "";
    switch (value) {
      case "today":
        startDate = today.startOf("day").format("YYYY-MM-DD");
        endDate = today.format("YYYY-MM-DD");
        break;
      case "yesterday":
        startDate = today
          .subtract(1, "days")
          .startOf("day")
          .format("YYYY-MM-DD");
        endDate = today.endOf("day").format("YYYY-MM-DD");
        break;
      case "this_week":
        startDate = today.startOf("week").format("YYYY-MM-DD");
        endDate = today.endOf("week").format("YYYY-MM-DD");
        break;
      case "this_month":
        startDate = today.startOf("month").format("YYYY-MM-DD");
        endDate = today.endOf("month").format("YYYY-MM-DD");
        break;
      default:
        startDate = "";
        endDate = "";
        break;
    }
    setReceiverSearch((prev) => ({
      ...prev,
      startDate,
      endDate,
    }));
  };

  const handleAssignedDateFilterChange = (e) => {
    const value = e.target.value;
    setAssignedDateFilter(value);
    const today = moment().endOf("day");
    let startDate = "";
    let endDate = "";
    switch (value) {
      case "today":
        startDate = today.startOf("day").format("YYYY-MM-DD");
        endDate = today.format("YYYY-MM-DD");
        break;
      case "yesterday":
        startDate = today
          .subtract(1, "days")
          .startOf("day")
          .format("YYYY-MM-DD");
        endDate = today.endOf("day").format("YYYY-MM-DD");
        break;
      case "this_week":
        startDate = today.startOf("week").format("YYYY-MM-DD");
        endDate = today.endOf("week").format("YYYY-MM-DD");
        break;
      case "this_month":
        startDate = today.startOf("month").format("YYYY-MM-DD");
        endDate = today.endOf("month").format("YYYY-MM-DD");
        break;
      default:
        startDate = "";
        endDate = "";
        break;
    }
    setAssignedSearch((prev) => ({
      ...prev,
      startDate,
      endDate,
    }));
  };

  const Addfilter = () => {
    const queryParams = {
      page: currentPage,
      limit: limitFilter,
      ...Object.fromEntries(
        Object.entries(search).filter(([_, value]) => value !== "")
      ),
    };
    dispatch(fetchSender(queryParams));
  };

  const resetFilter = () => {
    setsearch({
      sender_paid_amount: "",
      username: "",
      search: "",
      startDate: "",
      endDate: "",
    });
    setLimitFilter(10);
    setDateFilter("default");
    setCurrentPage(1);

    dispatch(
      fetchSender({
        page: 1,
        limit: 10,
      })
    );
  };

  const ReceiverResetFilter = () => {
    setReceiverSearch({
      amount: "",
      username: "",
      search: "",
      startDate: "",
      endDate: "",
    });
    setReceiverLimitFilter(10);
    setReceiverDateFilter("default");
    setReceiverCurrentPage(1);

    dispatch(
      fetchReceiver({
        page: 1,
        limit: 10,
      })
    );
  };

  const assignedResetFilter = () => {
    setAssignedSearch({
      amount: "",
      username: "",
      search: "",
      startDate: "",
      endDate: "",
    });
    setAssignedLimitFilter(10);
    setAssignedDateFilter("default");
    setAssignedCurrentPage(1);

    dispatch(
      fetchAssignedHelp({
        page: 1,
        limit: 10,
      })
    );
  };

  useEffect(() => {
    const queryParams = {
      page: currentPage,
      limit: 10,
      ...Object.fromEntries(
        Object.entries(search).filter(([_, value]) => value !== "")
      ),
    };
    fetchSenderList(queryParams);
  }, [dispatch, currentPage, limitFilter, search]);

  useEffect(() => {
    const queryParams = {
      page: receiverCurrentPage,
      limit: 10,
      ...Object.fromEntries(
        Object.entries(receiverSearch).filter(([_, value]) => value !== "")
      ),
    };

    fetchReceiverList(queryParams);
  }, [dispatch, receiverCurrentPage, receiverLimitFilter, receiverSearch]);

  useEffect(() => {
    const queryParams = {
      page: assignedCurrentPage,
      limit: 10,
      ...Object.fromEntries(
        Object.entries(assignedSearch).filter(([_, value]) => value !== "")
      ),
    };

    fetchAssignedList(queryParams);
  }, [dispatch, assignedCurrentPage, assignedLimitFilter, assignedSearch]);

  useEffect(() => {
    setReceiverTotalPages(receiverPayment?.totalPages);
  }, [receiverPayment]);

  useEffect(() => {
    setTotalPages(senderPayment?.totalPages);
  }, [senderPayment]);

  useEffect(() => {
    setAssignedTotalPages(assignedPayment?.totalPages);
  }, [assignedPayment]);

  const [selectAll, setSelectAll] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [tx_Ids, setTx_Ids] = useState([]);

  const toggleSelectAll = () => {
    setSelectAll(!selectAll);
    console.log(selectAll);
    if (!selectAll) {
      setSelectedRows(senderPayment?.data?.map((_, index) => index));
      setTx_Ids(senderPayment?.data?.map((row) => row.order_Id));
    } else {
      setSelectedRows([]);
      setTx_Ids([]);
    }
  };

  const toggleSelectRow = (index, order_Id) => {
    if (selectedRows.includes(index)) {
      setSelectedRows(selectedRows.filter((rowIndex) => rowIndex !== index));
      setTx_Ids(tx_Ids.filter((rowIndex) => rowIndex !== order_Id));
    } else {
      setSelectedRows([...selectedRows, index]);
      setTx_Ids([...tx_Ids, order_Id]);
    }
  };

  const [receiverSelectAll, setReceiverSelectAll] = useState(false);
  const [receiverSelectedRows, setReceiverSelectedRows] = useState([]);
  const [receiverTx_Ids, setReceiverTx_Ids] = useState([]);

  const receiverToggleSelectAll = () => {
    setReceiverSelectAll(!receiverSelectAll);
    console.log(receiverSelectAll);
    if (!receiverSelectAll) {
      setReceiverSelectedRows(receiverPayment?.data?.map((_, index) => index));
      setReceiverTx_Ids(receiverPayment?.data?.map((row) => row.tx_Id));
    } else {
      setReceiverSelectedRows([]);
      setReceiverTx_Ids([]);
    }
  };

  const receiverToggleSelectRow = (index, tx_Id) => {
    if (receiverSelectedRows.includes(index)) {
      setReceiverSelectedRows(
        receiverSelectedRows.filter((rowIndex) => rowIndex !== index)
      );
      setReceiverTx_Ids(
        receiverTx_Ids.filter((rowIndex) => rowIndex !== tx_Id)
      );
    } else {
      setReceiverSelectedRows([...receiverSelectedRows, index]);
      setReceiverTx_Ids([...receiverTx_Ids, tx_Id]);
    }
  };

  const handleAssignHelp = async () => {
    try {
      console.log("order_Id,tx_Id", tx_Ids, receiverTx_Ids);
      await dispatch(assignHelpRequest(tx_Ids, receiverTx_Ids));
      setSelectedRows([]);
      setTx_Ids([]);
      setReceiverSelectedRows([]);
      setReceiverTx_Ids([]);
      dispatch(
        fetchSender({
          page: 1,
          limit: 10,
        })
      );
      dispatch(
        fetchReceiver({
          page: 1,
          limit: 10,
        })
      );
      dispatch(
        fetchAssignedHelp({
          page: 1,
          limit: 10,
        })
      );
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <React.Fragment>
      <Container>
        <h3>Assign Help</h3>
        <div className="helpButton">
          <button className="block-btn" onClick={handleAssignHelp}>
            Assign Help
          </button>
        </div>
        <Row md="12">
          {" "}
          <Col md="6">
            {" "}
            <div className="pagePath">
              <h5 className="m-0">Sender Payment</h5>
            </div>
            <Row id="filterRow">
              <span className="filterL">
                <div className="d-flex justify-content-between">
                  <div onClick={() => toggleFilter()}>
                    <i>
                      <BiFilter />
                    </i>
                    Filter
                  </div>
                  <div className="align-content-center">
                    Total Credited Amount : {senderPayment?.creditSum}
                  </div>
                </div>
              </span>

              <Row className="p-0 m-0" style={{ display: filter }}>
                <Col lg="2" md="4">
                  <div>
                    <input
                      type="text"
                      name="search"
                      placeholder="Search By Name, Username"
                      value={search.search}
                      onChange={handleChange}
                    />
                  </div>
                </Col>
                <Col lg="2" md="4">
                  <div>
                    <input
                      type="number"
                      name="sender_paid_amount"
                      placeholder="Search By Amount"
                      value={search.sender_paid_amount}
                      onChange={handleChange}
                    />
                  </div>
                </Col>
                {/*<Col lg="2" md="4">
              <div>
              
              <input type="text" name='username' placeholder="Search By UserName" value={search.username} onChange={handleChange} />
               </div>
            </Col>*/}
                <Col lg="2" md="4">
                  <div>
                    <input
                      type="date"
                      name="startDate"
                      placeholder="Start Date"
                      value={search.startDate}
                      onChange={handleChange}
                    />
                  </div>
                </Col>
                <Col lg="2" md="4">
                  <div>
                    <input
                      type="date"
                      name="endDate"
                      placeholder="End Date"
                      value={search.endDate}
                      onChange={handleChange}
                    />
                  </div>
                </Col>
                <Col lg="2" md="4">
                  <div>
                    <select
                      name="dateFilter"
                      value={dateFilter}
                      onChange={handleDateFilterChange}
                    >
                      <option value="default">Default</option>
                      <option value="today">Today</option>
                      <option value="yesterday">Yesterday</option>
                      <option value="this_week">This Week</option>
                      <option value="this_month">This Month</option>
                    </select>
                  </div>
                </Col>
                <Col lg="2" md="4">
                  <div>
                    <select
                      name=""
                      value={limitFilter}
                      onChange={handleLimitChange}
                    >
                      <option value="">--All--</option>
                      <option value="20">20</option>
                      <option value="50">50</option>
                      <option value="100">100</option>
                      <option value="200">200</option>
                    </select>
                  </div>
                </Col>
                <div>
                  <button className="save filter" onClick={Addfilter}>
                    Filter{" "}
                    <i>
                      <BiFilter />
                    </i>
                  </button>
                  <button className="cancel reset" onClick={resetFilter}>
                    Reset{" "}
                    <i>
                      <GrPowerReset />
                    </i>
                  </button>
                </div>
              </Row>
            </Row>
            <div id="directIncomeDiv">
              <Row id="directTableRow" className="text-center">
                <Col md="12" className="p-0">
                  <div style={{ width: "100%" }}>
                    <table style={{ margin: "5px 0px 5px 0px" }}>
                      <tr>
                        <th>
                          <div className="checkbox-cell">
                            <input
                              type="checkbox"
                              checked={selectAll}
                              onChange={toggleSelectAll}
                              className="checkbox-input"
                              id="selectAll"
                            />
                            <label
                              htmlFor="selectAll"
                              className="checkbox-label"
                            >
                              <span
                                className={`custom-checkboxSR ${
                                  selectAll ? "checked" : ""
                                }`}
                              />
                              <span className="checkbox-text">Select All</span>
                            </label>
                          </div>
                        </th>
                        <th>S. No.</th>
                        <th>Order Id</th>
                        <th>Name</th>
                        <th>Username</th>
                        <th>Amount</th>
                        <th>Paid Amount</th>
                        <th>Paid Amount in Process</th>
                        <th>Pending Amount</th>
                        <th>Date</th>
                      </tr>
                      {senderPayment?.data?.map((row, index) => (
                        <tr key={index}>
                          <td>
                            <div className="checkButton">
                              <input
                                type="checkbox"
                                checked={selectedRows.includes(index)}
                                onChange={() =>
                                  toggleSelectRow(index, row.order_Id)
                                }
                                className="checkbox-input"
                                id={`row-${index}`}
                              />
                              <label
                                htmlFor={`row-${index}`}
                                className="checkbox-labelSR"
                              >
                                <span
                                  className={`custom-checkboxSR ${
                                    selectedRows.includes(index)
                                      ? "checked"
                                      : ""
                                  }`}
                                />
                              </label>
                            </div>
                          </td>
                          <td>{index + 1 + limitFilter * (currentPage - 1)}</td>
                          <td>{row.order_Id}</td>
                          <td>{row.name}</td>
                          <td>{row.username}</td>
                          <td>{row.amount}</td>
                          <td>{row.paid_amount}</td>
                          <td>{row.paid_amount_in_process}</td>
                          <td>{row.pending_amount}</td>
                          <td>
                            {moment(row?.createdAt).format(
                              "DD-MM-YYYY   &   HH:mm"
                            )}
                          </td>
                        </tr>
                      ))}
                    </table>
                  </div>
                </Col>
              </Row>
            </div>
            <Row>
              <Col md="12">
                {
                  <PaginationComponent
                    currentPage={currentPage}
                    totalPages={totalPages}
                    onPageChange={handlePagination}
                  />
                }
              </Col>
            </Row>
          </Col>
          <Col md="6">
            {" "}
            <div className="pagePath">
              <h5 className="m-0">Receiver Payment</h5>
            </div>
            <Row id="filterRow">
              <span className="filterL">
                <div className="d-flex justify-content-between">
                  <div onClick={() => receiverToggleFilter()}>
                    <i>
                      <BiFilter />
                    </i>
                    Filter
                  </div>
                  <div className="align-content-center">
                    Total Credited Amount : {receiverPayment?.creditSum}
                  </div>
                </div>
              </span>

              <Row className="p-0 m-0" style={{ display: filter }}>
                <Col lg="2" md="4">
                  <div>
                    <input
                      type="text"
                      name="search"
                      placeholder="Search By Name, Username"
                      value={receiverSearch.search}
                      onChange={handleReceiverChange}
                    />
                  </div>
                </Col>
                <Col lg="2" md="4">
                  <div>
                    <input
                      type="number"
                      name="amount"
                      placeholder="Search By Amount"
                      value={receiverSearch.amount}
                      onChange={handleReceiverChange}
                    />
                  </div>
                </Col>
                {/*<Col lg="2" md="4">
              <div>
              
              <input type="text" name='username' placeholder="Search By UserName" value={search.username} onChange={handleChange} />
               </div>
            </Col>*/}
                <Col lg="2" md="4">
                  <div>
                    <input
                      type="date"
                      name="startDate"
                      placeholder="Start Date"
                      value={receiverSearch.startDate}
                      onChange={handleReceiverChange}
                    />
                  </div>
                </Col>
                <Col lg="2" md="4">
                  <div>
                    <input
                      type="date"
                      name="endDate"
                      placeholder="End Date"
                      value={receiverSearch.endDate}
                      onChange={handleReceiverChange}
                    />
                  </div>
                </Col>
                <Col lg="2" md="4">
                  <div>
                    <select
                      name="receiverDateFilter"
                      value={receiverDateFilter}
                      onChange={handleReceiverDateFilterChange}
                    >
                      <option value="default">Default</option>
                      <option value="today">Today</option>
                      <option value="yesterday">Yesterday</option>
                      <option value="this_week">This Week</option>
                      <option value="this_month">This Month</option>
                    </select>
                  </div>
                </Col>
                <Col lg="2" md="4">
                  <div>
                    <select
                      name=""
                      value={receiverLimitFilter}
                      onChange={handleReceiverLimitChange}
                    >
                      <option value="">--All--</option>
                      <option value="20">20</option>
                      <option value="50">50</option>
                      <option value="100">100</option>
                      <option value="200">200</option>
                    </select>
                  </div>
                </Col>
                <div>
                  <button className="save filter">
                    Filter{" "}
                    <i>
                      <BiFilter />
                    </i>
                  </button>
                  <button
                    className="cancel reset"
                    onClick={ReceiverResetFilter}
                  >
                    Reset{" "}
                    <i>
                      <GrPowerReset />
                    </i>
                  </button>
                </div>
              </Row>
            </Row>
            <div id="directIncomeDiv">
              <Row id="directTableRow" className="text-center">
                <Col md="12" className="p-0">
                  <div style={{ width: "100%" }}>
                    <table style={{ margin: "5px 0px 5px 0px" }}>
                      <tr>
                        <th>
                          <div className="checkbox-cell">
                            <input
                              type="checkbox"
                              checked={receiverSelectAll}
                              onChange={receiverToggleSelectAll}
                              className="checkbox-input"
                              id="receiverSelectAll"
                            />
                            <label
                              htmlFor="receiverSelectAll"
                              className="checkbox-label"
                            >
                              <span
                                className={`custom-checkboxSR ${
                                  receiverSelectAll ? "checked" : ""
                                }`}
                              />
                              <span className="checkbox-text">Select All</span>
                            </label>
                          </div>
                        </th>
                        <th>S. No.</th>
                        <th>Tx Id</th>
                        <th>Name</th>
                        <th>Username</th>
                        <th>Amount</th>
                        <th>Paid Amount</th>
                        <th>Pending Amount</th>
                        <th>Progress Paid Amount</th>
                        <th>Date</th>
                      </tr>
                      {receiverPayment?.data?.map((row, index) => (
                        <tr key={index}>
                          <td>
                            <div className="checkButton">
                              <input
                                type="checkbox"
                                checked={receiverSelectedRows.includes(index)}
                                onChange={() =>
                                  receiverToggleSelectRow(index, row.tx_Id)
                                }
                                className="checkbox-input"
                                id={`receiver-row-${index}`}
                              />
                              <label
                                htmlFor={`receiver-row-${index}`}
                                className="checkbox-labelSR"
                              >
                                <span
                                  className={`custom-checkboxSR ${
                                    receiverSelectedRows.includes(index)
                                      ? "checked"
                                      : ""
                                  }`}
                                />
                              </label>
                            </div>
                          </td>
                          <td>{index + 1 + limitFilter * (currentPage - 1)}</td>
                          <td>{row.tx_Id}</td>
                          <td>{row.name}</td>
                          <td>{row.username}</td>
                          <td>{row.amount}</td>
                          <td>{row.paid_amount}</td>
                          <td>{row.pending_amount}</td>
                          <td>{row.paid_amount_in_process}</td>
                          <td>
                            {moment(row?.createdAt).format(
                              "DD-MM-YYYY   &   HH:mm"
                            )}
                          </td>
                        </tr>
                      ))}
                    </table>
                  </div>
                </Col>
              </Row>
            </div>
            <Row>
              <Col md="12">
                {
                  <PaginationComponent
                    currentPage={receiverCurrentPage}
                    totalPages={receiverTotalPages}
                    onPageChange={handleReceiverPagination}
                  />
                }
              </Col>
            </Row>
          </Col>
        </Row>

        <div className="pagePath">
          <h5 className="m-0">Assigned Help Payments</h5>
        </div>
        <Row id="filterRow">
          <span className="filterL">
            <div className="d-flex justify-content-between">
              <div onClick={() => assignedToggleFilter()}>
                <i>
                  <BiFilter />
                </i>
                Filter
              </div>
              <div className="align-content-center">
                Total Credited Amount : {assignedPayment?.creditSum}
              </div>
            </div>
          </span>

          <Row className="p-0 m-0" style={{ display: filter }}>
            <Col lg="2" md="4">
              <div>
                <input
                  type="text"
                  name="search"
                  placeholder="Search By Name, Username"
                  value={assignedSearch.search}
                  onChange={handleAssignedChange}
                />
              </div>
            </Col>
            <Col lg="2" md="4">
              <div>
                <input
                  type="number"
                  name="sender_paid_amount"
                  placeholder="Search By Amount"
                  value={assignedSearch.sender_paid_amount}
                  onChange={handleAssignedChange}
                />
              </div>
            </Col>
            {/*<Col lg="2" md="4">
              <div>
              
              <input type="text" name='username' placeholder="Search By UserName" value={search.username} onChange={handleChange} />
               </div>
            </Col>*/}
            <Col lg="2" md="4">
              <div>
                <input
                  type="date"
                  name="startDate"
                  placeholder="Start Date"
                  value={assignedSearch.startDate}
                  onChange={handleAssignedChange}
                />
              </div>
            </Col>
            <Col lg="2" md="4">
              <div>
                <input
                  type="date"
                  name="endDate"
                  placeholder="End Date"
                  value={assignedSearch.endDate}
                  onChange={handleAssignedChange}
                />
              </div>
            </Col>
            <Col lg="2" md="4">
              <div>
                <select
                  name="assignedDateFilter"
                  value={assignedDateFilter}
                  onChange={handleAssignedDateFilterChange}
                >
                  <option value="default">Default</option>
                  <option value="today">Today</option>
                  <option value="yesterday">Yesterday</option>
                  <option value="this_week">This Week</option>
                  <option value="this_month">This Month</option>
                </select>
              </div>
            </Col>
            <Col lg="2" md="4">
              <div>
                <select
                  name=""
                  value={assignedLimitFilter}
                  onChange={handleAssignedLimitChange}
                >
                  <option value="">--All--</option>
                  <option value="20">20</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                  <option value="200">200</option>
                </select>
              </div>
            </Col>
            <div>
              <button className="save filter">
                Filter{" "}
                <i>
                  <BiFilter />
                </i>
              </button>
              <button className="cancel reset" onClick={assignedResetFilter}>
                Reset{" "}
                <i>
                  <GrPowerReset />
                </i>
              </button>
            </div>
          </Row>
        </Row>
        <div id="directIncomeDiv">
          <Row id="directTableRow" className="text-center">
            <Col md="12" className="p-0">
              <div style={{ width: "100%" }}>
                <table style={{ margin: "5px 0px 5px 0px" }}>
                  <tr>
                    {/* <th>
                      <div className="checkbox-cell">
                        <input
                          type="checkbox"
                          checked={selectAll}
                          onChange={toggleSelectAll}
                          className="checkbox-input"
                          id="selectAll"
                        />
                        <label htmlFor="selectAll" className="checkbox-label">
                          <span
                            className={`custom-checkbox ${
                              selectAll ? "checked" : ""
                            }`}
                          />
                          <span className="checkbox-text">Select All</span>
                        </label>
                      </div>
                    </th> */}
                    <th>S. No.</th>
                    <th>Sender Name</th>
                    <th>Sender Username</th>
                    <th>Help Id</th>
                    <th>Sender Paid Amount</th>
                    <th>Sender Paid Date</th>
                    <th>Receiver Name</th>
                    <th>Receiver Username</th>
                    <th>Receiver Get Amount</th>
                    <th>Receiver Get Date</th>
                    <th>Assigned Date</th>
                    <th>Target Date</th>
                  </tr>
                  {assignedPayment?.data?.map((row, index) => (
                    <tr key={index}>
                      {/* <td>
                        <div className="checkButton">
                          <input
                            type="checkbox"
                            checked={selectedRows.includes(index)}
                            onChange={() =>
                              toggleSelectRow(index, row.order_Id)
                            }
                            className="checkbox-input"
                            id={`row-${index}`}
                          />
                          <label
                            htmlFor={`row-${index}`}
                            className="checkbox-label1"
                          >
                            <span
                              className={`custom-checkbox ${
                                selectedRows.includes(index) ? "checked" : ""
                              }`}
                            />
                          </label>
                        </div>
                      </td> */}
                      <td>{index + 1 + limitFilter * (currentPage - 1)}</td>
                      <td>{row.sender_name}</td>
                      <td>{row.sender_username}</td>
                      <td>{row.help_Id}</td>
                      <td>{row.sender_paid_amount}</td>
                      {row?.sender_paid_amount_date == null ? (
                        <td></td>
                      ) : (
                        <td>
                          {moment(row?.sender_paid_amount_date).format(
                            "DD-MM-YYYY   &   HH:mm"
                          )}
                        </td>
                      )}

                      <td>{row.receiver_name}</td>
                      <td>{row.receiver_username}</td>
                      <td>{row.reciever_get_amount}</td>

                      {row?.reciever_get_amount_date == null ? (
                        <td></td>
                      ) : (
                        <td>
                          {moment(row?.reciever_get_amount_date).format(
                            "DD-MM-YYYY   &   HH:mm"
                          )}
                        </td>
                      )}

                      <td>
                        {" "}
                        {moment(row?.added_on).format("DD-MM-YYYY   &   HH:mm")}
                      </td>
                      <td>
                        {" "}
                        {moment(row?.target_date).format(
                          "DD-MM-YYYY   &   HH:mm"
                        )}
                      </td>
                    </tr>
                  ))}
                </table>
              </div>
            </Col>
          </Row>
        </div>
        <Row>
          <Col md="12">
            {
              <PaginationComponent
                currentPage={assignedCurrentPage}
                totalPages={assignedTotalPages}
                onPageChange={handleAssignedPagination}
              />
            }
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
};

export default PaymentSendReceive;
