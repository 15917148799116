// src/actions/apiActions.js
import apiCall from '../API/API';
import { toast } from 'react-toastify';

const createApiAction = ({ method, endpoint, data, requestType, successType, failureType,showToast }) => async (dispatch) => {
  dispatch({ type: requestType }); // Optional: dispatch a request action if needed

  const { data: responseData, error } = await apiCall(method, endpoint, data,showToast);
  /*console.log("1111111111111",responseData)*/
  if (error) {
    
    dispatch({ type: failureType, payload: error });
  } else {
    dispatch({ type: successType, payload: responseData });
  }
};

export default createApiAction;
