// src/actions/adminActions.js
import createApiAction from './apiActions';
import * as actionTypes from './actionTypes';
import { LOGOUT } from './actionTypes';

export const login = (credentials) => createApiAction({
  method: 'post',
  endpoint: '/login',
  data: credentials,
  requestType: actionTypes.LOGIN_REQUEST,
  successType: actionTypes.LOGIN_SUCCESS,
  failureType: actionTypes.LOGIN_FAILURE,
  showToast:true,

  onFailure: (dispatch) => {
    dispatch(logout()); 
  }
});

export const logout = () => (dispatch) => {
  dispatch({ type: LOGOUT }); 
  localStorage.clear();
};

export const fetchPaymentOptions = () => createApiAction({
  method: 'get',
  endpoint: '/save_payment_option',
  requestType: actionTypes.FETCH_PAYMENT_OPTIONS_REQUEST,
  successType: actionTypes.FETCH_PAYMENT_OPTIONS_SUCCESS,
  failureType: actionTypes.FETCH_PAYMENT_OPTIONS_FAILURE,
  showToast:false
});

export const fetchAllUsers = (queryParams) => createApiAction({
  method: 'get',
  endpoint: `/get-all-users?${new URLSearchParams(queryParams).toString()}`,
  requestType: actionTypes.FETCH_ALL_USERS_REQUESTS_REQUEST,
  successType: actionTypes.FETCH_ALL_USERS_REQUESTS_SUCCESS,
  failureType: actionTypes.FETCH_ALL_USERS_REQUESTS_FAILURE,
  showToast:false
});
export const fetchAllOrder = (queryParams) => createApiAction({
  method: 'get',
  endpoint: `/get_all_orders?${new URLSearchParams(queryParams).toString()}`,
  requestType: actionTypes.FETCH_ALL_ORDER_REQUEST,
  successType: actionTypes.FETCH_ALL_ORDER_SUCCESS,
  failureType: actionTypes.FETCH_ALL_ORDER_FAILURE,
  showToast:false
});
export const fetchSender = (queryParams) => createApiAction({
  method: 'get',
  endpoint: `/sender_payment?${new URLSearchParams(queryParams).toString()}`,
  requestType: actionTypes.FETCH_ALL_SENDER_REQUEST,
  successType: actionTypes.FETCH_ALL_SENDER_SUCCESS,
  failureType: actionTypes.FETCH_ALL_SENDER_FAILURE,
  showToast:false
});
export const fetchReceiver = (queryParams) => createApiAction({
  method: 'get',
  endpoint: `/receiver_payment?${new URLSearchParams(queryParams).toString()}`,
  requestType: actionTypes.FETCH_ALL_RECEIVER_REQUEST,
  successType: actionTypes.FETCH_ALL_RECEIVER_SUCCESS,
  failureType: actionTypes.FETCH_ALL_RECEIVER_FAILURE,
  showToast:false
});
export const assignHelpRequest = (order_Id,tx_Id) => createApiAction({
  method: 'post',
  endpoint: '/asign_help_link',
  data: { order_Id, tx_Id },
  requestType: actionTypes.ASSIGN_HELP_REQUEST_REQUEST,
  successType: actionTypes.ASSIGN_HELP_REQUEST_SUCCESS,
  failureType: actionTypes.ASSIGN_HELP_REQUEST_FAILURE,
  showToast:true
});
export const fetchAssignedHelp = (queryParams) => createApiAction({
  method: 'get',
  endpoint: `/asigned_help_link?${new URLSearchParams(queryParams).toString()}`,
  requestType: actionTypes.FETCH_ASSIGNED_HELP_REQUEST,
  successType: actionTypes.FETCH_ASSIGNED_HELP_SUCCESS,
  failureType: actionTypes.FETCH_ASSIGNED_HELP_FAILURE,
  showToast:false
});
export const fetchAllRewards = (queryParams) => createApiAction({
  method: 'get',
  endpoint: `/all_reward_request?${new URLSearchParams(queryParams).toString()}`,
  requestType: actionTypes.FETCH_ALL_REWARD_REQUEST,
  successType: actionTypes.FETCH_ALL_REWARD_SUCCESS,
  failureType: actionTypes.FETCH_ALL_REWARD_FAILURE,
  showToast:false
});
export const fetchLevelRewards = (queryParams) => createApiAction({
  method: 'get',
  endpoint: `/all_level_request?${new URLSearchParams(queryParams).toString()}`,
  requestType: actionTypes.FETCH_ALL_LEVEL_REQUEST,
  successType: actionTypes.FETCH_ALL_LEVEL_SUCCESS,
  failureType: actionTypes.FETCH_ALL_LEVEL_FAILURE,
  showToast:false
});
export const approveRewardRequest = (requestIds,rewardItem) => createApiAction({
  method: 'post',
  endpoint: '/approve-reward-request',
  data: { requestIds, rewardItem },
  requestType: actionTypes.APPROVE_REWARD_REQUEST_REQUEST,
  successType: actionTypes.APPROVE_REWARD_REQUEST_SUCCESS,
  failureType: actionTypes.APPROVE_REWARD_REQUEST_FAILURE,
  showToast:true
});
export const rejectRewardRequest = (requestIds,rewardItem,message) => createApiAction({
  method: 'post',
  endpoint: '/reject-reward-request',
  data: { requestIds,rewardItem,message },
  requestType: actionTypes.REJECT_REWARD_REQUEST_REQUEST,
  successType: actionTypes.REJECT_REWARD_REQUEST_SUCCESS,
  failureType: actionTypes.REJECT_REWARD_REQUEST_FAILURE,
  showToast:true
});
export const fetchAllTransaction = (queryParams) => createApiAction({
  method: 'get',
  endpoint: `/get-payment-transaction?${new URLSearchParams(queryParams).toString()}`,
  requestType: actionTypes.FETCH_ALL_PAYMENT_REQUESTS_REQUEST,
  successType: actionTypes.FETCH_ALL_PAYMENT_REQUESTS_SUCCESS,
  failureType: actionTypes.FETCH_ALL_PAYMENT_REQUESTS_FAILURE,
  showToast:false
});

export const approvePaymentRequest = (requestIds) => createApiAction({
  method: 'post',
  endpoint: '/approve-payment-request',
  data: { requestIds },
  requestType: actionTypes.APPROVE_PAYMENT_REQUEST_REQUEST,
  successType: actionTypes.APPROVE_PAYMENT_REQUEST_SUCCESS,
  failureType: actionTypes.APPROVE_PAYMENT_REQUEST_FAILURE,
  showToast:true
});

export const approveWithdrawalRequest = (requestIds) => createApiAction({
  method: 'post',
  endpoint: '/approve-withdrawal-request',
  data: { requestIds },
  requestType: actionTypes.APPROVE_PAYMENT_REQUEST_REQUEST,
  successType: actionTypes.APPROVE_PAYMENT_REQUEST_SUCCESS,
  failureType: actionTypes.APPROVE_PAYMENT_REQUEST_FAILURE,
  showToast:true
});

export const rejectPaymentRequest = (requestIds,message) => createApiAction({
  method: 'post',
  endpoint: '/reject-payment-request',
  data: { requestIds,message },
  requestType: actionTypes.REJECT_PAYMENT_REQUEST_REQUEST,
  successType: actionTypes.REJECT_PAYMENT_REQUEST_SUCCESS,
  failureType: actionTypes.REJECT_PAYMENT_REQUEST_FAILURE,
  showToast:true
});
export const rejectWithdrawalRequest = (requestIds,message) => createApiAction({
  method: 'post',
  endpoint: '/reject-withdrawal-request',
  data: { requestIds,message },
  requestType: actionTypes.REJECT_PAYMENT_REQUEST_REQUEST,
  successType: actionTypes.REJECT_PAYMENT_REQUEST_SUCCESS,
  failureType: actionTypes.REJECT_PAYMENT_REQUEST_FAILURE,
  showToast:true
});

export const fetchDashboardData = () => createApiAction({
  method: 'get',
  endpoint: '/get-dashboard-data',
  requestType: actionTypes.FETCH_DASHBOARD_DATA_REQUEST,
  successType: actionTypes.FETCH_DASHBOARD_DATA_SUCCESS,
  failureType: actionTypes.FETCH_DASHBOARD_DATA_FAILURE,
  showToast:false
});

export const fetchPaymentTransactions = () => createApiAction({
  method: 'get',
  endpoint: '/get-payment-transaction',
  requestType: actionTypes.FETCH_PAYMENT_TRANSACTIONS_REQUEST,
  successType: actionTypes.FETCH_PAYMENT_TRANSACTIONS_SUCCESS,
  failureType: actionTypes.FETCH_PAYMENT_TRANSACTIONS_FAILURE,
  showToast:false
});
