// src/reducers/accountsReducer.js
import * as actionTypes from "../Actions/actionTypes";

const initialState = {
  value: null,
  loading: false,
  error: { code: 0, message: null },
};

const accountsReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_ACCOUNT_REQUEST:
      return { ...state, loading: true, error: null };

    case actionTypes.SET_ACCOUNT_SUCCESS:
      return { ...state, loading: false, value: action.payload, error: null };

    case actionTypes.SET_ACCOUNT_FAILURE:
      return { ...state, loading: false, error: action.payload };

    default:
      return state;
  }
};

export default accountsReducer;
