import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import apiCall from './../../API/API'; 
import "./Upi.css";
import { Container, Row, Col, Button } from "react-bootstrap";
import AlertMessage from '../../Component/AlertMessage/AlertMessage';
import { fetchAllTransaction } from '../../Actions/adminActions';
import { BsCheckCircle } from 'react-icons/bs';
import { BiErrorCircle } from 'react-icons/bi';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const Web3 = () => {
  const [chain, setChain] = useState("");
  const [address, setAddress] = useState("");
  const [loading, setLoading] = useState(false); 
  const [error, setError] = useState(''); 
  const [success, setSuccess] = useState('null');
  const [chainData, setChainData] = useState([]);
  const [defaultChainData,setDefaultChainData] = useState([]);
  const { paymentRequests } = useSelector((state) => state.admin);
  const hasFetchedData = useRef(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const fetchChainData = async () => {
    const { data, error } = await apiCall('GET', '/get-payment-method');
    if (error) {
      toast.error(error.message || "An error occurred");
    } else {
        setChainData(data?.activeOptions?.web3?.chains);
      const defaultChain = data?.activeOptions?.web3?.chains?.find(chain => chain.isDefault);
      setDefaultChainData(defaultChain?.address || null);
    }
  };

  useEffect(() => {
    if (!hasFetchedData.current) {
      fetchChainData();
      hasFetchedData.current = true;
    }
  }, []);
  
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const { data, error } = await apiCall('POST', '/add-web3-details', { chain,address });
    if (error) {
      toast.error(error.message || "An error occurred");
    } else {
      toast.success(data.message || "Bank details submitted successfully");
      setChain('');
      setAddress('');   
      fetchChainData();
    }

    setLoading(false);
  };

  const handleDelete = async (address) => {
    console.log('address',address);
    const { data, error } = await apiCall('POST', `/delete-web3-details`, { address });
    if (error) {
      toast.error(error.message || "An error occurred");
    } else {
      toast.success(data.message || "Bank detail deleted successfully");
      fetchChainData();
    }
  };

  const handleDefault = async (address) => {
    console.log('address',address);
    const { data, error } = await apiCall('POST', `/set-default-web3-details`, { address });
    if (error) {
      toast.error(error.message || "An error occurred");
    } else {
      toast.success(data.message || "Bank detail set default successfully");
      fetchChainData();
    }
  };

  return (
    <React.Fragment>
      <Container className="mt-4">
        <section id="bankForm">
          <div className="d-flex justify-content-center">
            <h2>Web3 Form</h2>
          </div>
          <section id="formContent">
            <div className="form">
              <Row>
                <Col lg={6} style={{ margin: 'auto' }}>
                  <form onSubmit={handleSubmit} className="registerForm">
                    <div id="formFields">
                      <div className="mb-3">
                        <label htmlFor="bankName">Chain</label>
                        <input
                          type="text"
                          id="bankName"
                          className="form-control"
                          value={chain}
                          placeholder="Enter your Chain"
                          onChange={(e) => setChain(e.target.value)}
                          required
                        />
                      </div>
                      <div className="mb-3">
                        <label htmlFor="ifscCode">Address</label>
                        <input
                          type="text"
                          id="ifscCode"
                          className="form-control"
                          value={address}
                          placeholder="Enter your Address"
                          onChange={(e) => setAddress(e.target.value)}
                          required
                        />
                      </div>
                      <Button type="submit" variant="primary" disabled={loading}>
                        {loading ? 'Submitting...' : 'Submit'}
                      </Button>
                      <Button
                        variant="secondary"
                        className="ms-2"
                        onClick={() => {
                            setChain('');
                            setAddress('');
                        }}
                      >
                        Cancel
                      </Button>
                    </div>
                  </form>
                </Col>
              </Row>
            </div>
          </section>
        </section>
        {/* {success === 'true' ? (
          <AlertMessage
            msg="Bank information submitted successfully"
            icon={<BsCheckCircle />}
          />
        ) : success === 'false' ? (
          <AlertMessage
            msg={error || 'Submission failed'}
            icon={<BiErrorCircle />}
          />
        ) : ''} */}
        
        <div id="directIncomeDiv">
        <Row id="directTableRow" className="text-center">
                        <Col md="12" className="p-0">
                            <div style={{ width: "100%" }}>
                                <table>
                                    <tr>
                                    <th>S No.</th>
                                    <th>Chain</th>
                                    <th>Address</th>
                                    <th>Delete Chain</th>
                                    <th>Set Default</th>                          
                                    </tr>
                                    {Array.isArray(chainData) && chainData?.map((x, i) => (
                      <tr key={i}>
                        <td className="text-center">{i + 1}</td>
                        <td className="text-center">{x?.chain}</td>
                        <td className="text-center">{x?.address}</td>
                        <td className="text-center">
                          <button 
                            className="block-btn"
                            onClick={() => handleDelete(x?.address)}>
                            Delete Chain
                          </button>
                        </td>
                        <td className="text-center">
                          <button
                            className={`block-btn ${defaultChainData === x?.address ? 'default-btn' : ''}`}
                            onClick={() => handleDefault(x?.address)}
                            disabled={defaultChainData === x?.address}>
                            {defaultChainData === x?.address ? 'Default' : 'Set Default'}
                          </button>
                        </td>
                      </tr>
                    ))}
                                </table>
                            </div>
                        </Col>
                    </Row>
        </div>
        <ToastContainer className="toast-container" />
      </Container>
    </React.Fragment>
  );
};

export default Web3;
