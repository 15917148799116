import React, { useEffect, useState, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import apiCall from "./../../API/API";
import { toast, ToastContainer } from "react-toastify";
import RoiIncome from "./RoiIncome";
import RoiLevelIncome from "./LevelIncome";
import RoyaltyIncome from "./MonthlySalaryIncome";
import LevelIncome from "./DirectIncome";
import "bootstrap/dist/css/bootstrap.min.css";
import "./PayoutControls.css";
import { Container, Row, Col, NavLink, Button } from "react-bootstrap";

const PayoutControls = () => {
  const { paymentRequests } = useSelector((state) => state.admin);
  const [incomes, setIncomes] = useState("");
  const [roiIncome, setRoiIncome] = useState("");
  const [roiLevelIncome, setRoiLevelIncome] = useState("");
  const [royaltyIncome, setRoyaltyIncome] = useState("");
  const [levelIncome, setLevelIncome] = useState("");
  const [totalIncome, setTotalIncome] = useState("");
  const [todayTotalIncome, setTodayTotalIncome] = useState("");
  const [todayRoiIncome, setTodayRoiIncome] = useState("");
  const [todayRoiLevelIncome, setTodayRoiLevelIncome] = useState("");
  const [todayRoyaltyIncome, setTodayRoyaltyIncome] = useState("");
  const [todayLevelIncome, setTodayLevelIncome] = useState("");

  // const hasFetchedData = useRef(false);

  // const fetchIncomes = async () => {
  //   const { data, error } = await apiCall("GET", "/total_payout");
  //   if (error) {
  //     toast.error(error.message || "An error occurred");
  //   } else {
  //     setRoiIncome(data.roi_income);
  //     setRoiLevelIncome(data.roi_level_income);
  //     setRoyaltyIncome(data.royality_income);
  //     setLevelIncome(data.level_income);
  //     setTotalIncome(data.total_incomes);
  //     setTodayTotalIncome(data.today_total_incomes);
  //     setTodayRoiIncome(data.today_roi_income);
  //     setTodayRoiLevelIncome(data.today_roi_level_income);
  //     setTodayRoyaltyIncome(data.today_royality_income);
  //     setTodayLevelIncome(data.today_level_income);
  //   }
  // };

  // useEffect(() => {
  //   if (!hasFetchedData.current) {
  //     fetchIncomes();
  //     hasFetchedData.current = true;
  //   }
  // }, []);

  // const bonuses = [
  //   {
  //     title: "ROI Income",
  //     value: "roi_income",
  //     total: roiIncome,
  //     today: todayRoiIncome,
  //   },
  //   {
  //     title: "ROI Level Income",
  //     value: "roilevel_income",
  //     total: roiLevelIncome,
  //     today: todayRoiLevelIncome,
  //   },
  //   {
  //     title: "Royalty Income",
  //     value: "royalty_income",
  //     total: royaltyIncome,
  //     today: todayRoyaltyIncome,
  //   },
  //   {
  //     title: "Direct Income",
  //     value: "level_income",
  //     total: levelIncome,
  //     today: todayLevelIncome,
  //   },
  // ];
  const [activeTab, setActiveTab] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const tab = params.get("tab");
    if (tab) {
      setActiveTab(tab);
    }
    // const activeTab =
    //   bonuses.find((bonus) => bonus.value === tab) || bonuses[0];
    // setActiveTab(activeTab);
    // console.log("activeTab", activeTab);
  }, [location.search]);

  function handleTabClick(tab) {
    setActiveTab(tab);
    navigate(`/dashboard/${tab}`);
  }

  const fetchWallets = async () => {
    try {
      const { data, error } = await apiCall("GET", "/getIncomes");
      if (data) {
        setIncomes(data);
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  var x = 0;
  useEffect(() => {
    if (x == 0) {
      fetchWallets();
      x++;
    }
  }, []);

  return (
    <React.Fragment>
      <Container>
        <div className="list-container">
          <ul className="list-group">
            <Row md="12" className="justify-content-center">
              <Col md="5" className="justify-center ">
                <div className="list-group-item1">
                  <h3>Total Income</h3>
                  <Row>
                    <Col>
                      <div className="list1">
                        <h4>{parseFloat(incomes?.total_incomes).toFixed(2)}</h4>
                        <p>Total</p>
                      </div>
                    </Col>
                    <Col>
                      <div className="list1">
                        <h4>
                          {parseFloat(incomes?.today_total_incomes).toFixed(2)}
                        </h4>
                        <p>Today</p>
                      </div>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
            {Array.isArray(incomes?.incomes) &&
              incomes?.incomes?.map((x, i) => (
                <Row md="12" className="justify-content-center">
                  <Col md="5" className="justify-center">
                    <NavLink
                      key={i}
                      className={`list-group-item ${
                        activeTab === x?.slug ? "active" : ""
                      }`}
                      onClick={() => handleTabClick(x?.slug)}
                      style={{ cursor: "pointer", display: "static" }}
                    >
                      <h3>{x.name}</h3>
                      <Row>
                        <Col>
                          <div className="list">
                            <h4>{parseFloat(x.total_income).toFixed(2)}</h4>
                            <p>Total</p>
                          </div>
                        </Col>
                        <Col>
                          <div className="list">
                            <h4>{parseFloat(x.today_income).toFixed(2)}</h4>
                            <p>Today</p>
                          </div>
                        </Col>
                      </Row>
                    </NavLink>
                  </Col>
                </Row>
              ))}
          </ul>
        </div>
        <ToastContainer className="toast-container" />
      </Container>
    </React.Fragment>
  );
};

export default PayoutControls;
