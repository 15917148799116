// src/Actions/accountActions.js
import * as actionTypes from "./actionTypes";

export const setAccountRequest = () => ({
  type: actionTypes.SET_ACCOUNT_REQUEST,
});

export const setAccountSuccess = (account) => ({
  type: actionTypes.SET_ACCOUNT_SUCCESS,
  payload: account,
});

export const setAccountFailure = (error) => ({
  type: actionTypes.SET_ACCOUNT_FAILURE,
  payload: error,
});
