import React, { useState, useCallback } from "react";
import { useLocation } from "react-router-dom";
import "./With.css";
import { Container, Row, Col } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import apiCall from "../../API/API";
import CopyComponent from "../../Component/CopyComponent/CopyComponent";
import {
  approveWithdrawalRequest,
  fetchAllTransaction,
  rejectWithdrawalRequest,
} from "../../Actions/adminActions";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const WithdrawDetails = () => {
  const location = useLocation();
  const { withdrawal } = location.state || {};
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [rejectionReason, setRejectionReason] = useState("");
  const [error, setError] = useState("");

  const handleApprove = async (tx_Id) => {
    try {
      await dispatch(approveWithdrawalRequest([tx_Id]));
      navigate("/dashboard/withdraw_controls");
    } catch (e) {
      console.log(e);
    }
  };
  const handleReject = async (tx_Id) => {
    try {
      if (!rejectionReason.trim()) {
        setError("Rejection reason is required.");
        return;
      }
      await dispatch(rejectWithdrawalRequest([tx_Id], rejectionReason));
      navigate("/dashboard/withdraw_controls");
    } catch (e) {
      console.log(e);
    }
  };
  const handleApproveWithAPI = async (tx_Id) => {
    console.log("tx_Id", tx_Id);
    try {
      const { data, error } = await apiCall(
        "POST",
        "/sendcryp-payment-transfer",
        { tx_Id }
      );
      if (error) {
        toast.error(error.message || "An error occurred!");
      } else {
        toast.success(data.message || "Request success");
        navigate("/dashboard/withdraw_controls");
      }
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <React.Fragment>
      <Container>
        <h3>Withdrawal Details</h3>
        <hr></hr>

        <Row id="directTabRow">
          <Col xs="12" sm="12" md="8" lg="8">
            <div className="table-responsive">
              <table className="detailTable">
                <tbody>
                  <tr>
                    <th>Name</th>
                    <td>:</td>
                    <td>{withdrawal?.name}</td>
                  </tr>
                  <tr>
                    <th>Username</th>
                    <td>:</td>
                    <td>{withdrawal?.username}</td>
                  </tr>
                  <tr>
                    <th>Amount</th>
                    <td>:</td>
                    <td>{parseFloat(withdrawal?.amount).toFixed(2)}</td>
                  </tr>
                  <tr>
                    <th>Account Details</th>
                    <td>:</td>
                    <td>
                      {withdrawal?.account &&
                        Object.entries(withdrawal?.account)
                          .filter(
                            ([key]) =>
                              key !== "_id" &&
                              key !== "status" &&
                              key !== "chain"
                          )
                          .map(([key, value]) => (
                            <div className="keyValue">
                              <p>{key} :</p>
                              <p>{value}</p>
                              {key === "address" ? (
                                <CopyComponent value={value} />
                              ) : null}
                            </div>
                          ))}
                    </td>
                  </tr>
                  <tr>
                    <th>Status</th>
                    <td>:</td>
                    <td>{withdrawal?.status === 0 ? "Pending" : "Approved"}</td>
                  </tr>
                  <tr>
                    <th>Date</th>
                    <td>:</td>
                    <td>
                      {moment(withdrawal?.createdAt).format(
                        "DD-MM-YYYY   &   HH:mm"
                      )}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </Col>
          <Col xs="12" sm="12" md="4" lg="4">
            <form>
              <div className="form-group">
                <label>Reason (Give Reason of cancellation)</label>
                <textarea
                  name="rejectionReason"
                  value={rejectionReason}
                  className="form-control"
                  onChange={(e) => {
                    setRejectionReason(e.target.value);
                    setError("");
                  }}
                ></textarea>
                {error && <small className="text-danger">{error}</small>}
              </div>
              <div className="form-group">
                <button
                  className="action-btn"
                  onClick={(e) => {
                    e.preventDefault();
                    handleApprove(withdrawal.tx_Id);
                  }}
                >
                  Approve
                </button>
                <button
                  className="action-btn"
                  onClick={(e) => {
                    e.preventDefault();
                    handleReject(withdrawal.tx_Id);
                  }}
                >
                  Reject
                </button>
              </div>
              <div className="form-group">
                <button
                  className="actionBtn"
                  onClick={(e) => {
                    e.preventDefault();
                    handleApproveWithAPI(withdrawal.tx_Id);
                  }}
                >
                  Approve With API
                </button>
              </div>
            </form>
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
};

export default WithdrawDetails;
