import React, { useState, useEffect, useRef } from 'react';
import './BonanzaSetting.css';
import { CiCirclePlus } from "react-icons/ci";
import {Row, Col, Container, Button} from 'react-bootstrap';
import apiCall from './../../API/API'; 
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import moment from 'moment';

export default function BonanzaSetting() {
    
    const [showInputs, setShowInputs] = useState(false);
    const [formValues, setFormValues] = useState({
        bonanza_name: '',
        startDate: '',
        endDate: '',
        required_business: '',
        required_direct: '',
        required_team: '',
        reward_item: '',
        amount: '',
        planId: '' // Changed from dropdownValue to planId
    });
    const [records, setRecords] = useState([]);
    const [editingIndex, setEditingIndex] = useState(null);
    const [planOptions, setPlanOptions] = useState(['Plan 1', 'Plan 2', 'Plan 3']); // Plan options
    const hasFetchedData = useRef(false);

        const fetchRecords = async () => {
            if (!formValues) {
                toast.error("Please fill in all fields");
                return;
              }
            try {              
                const { data, error } = await apiCall('GET', '/get-bonanza/1');
                if (data) {
                    toast.success(data.message)
                    setRecords(data); 
                } else if (error) {
                    toast.error(error.message || "An error occurred");
                }
            } catch (error) {
                toast.error(error.message || "An error occurred");
            }
        };

        useEffect(() => {
            if (!hasFetchedData.current) {
                fetchRecords();
                hasFetchedData.current = true;
              }
        
        }, []);



    const handleAddRecordClick = () => {
        setEditingIndex(null);
        setFormValues({
            bonanza_name: '',
            startDate: '',
            endDate: '',
            required_business: '',
            required_direct: '',
            required_team: '',
            reward_item: '',
            amount: '',
            planId: ''
        });
        setShowInputs(true);
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormValues({ ...formValues, [name]: value });
    };

    const hasSpecialCharacters = (str) => /[^a-zA-Z0-9\s]/.test(str);

    const validateForm = () => {
        const { bonanza_name} = formValues;
        if (Object.values(formValues).some(value => value === '')) {
            toast.error("All fields are required.");
            return false;
        }
        if (hasSpecialCharacters(bonanza_name)) {
            toast.error("Special characters are not allowed");
            return false;
        }
        return true;
    };

    const handleSave = async (e) => {
        // console.log("records",records);
        // console.log(editingIndex-1);
        // console.log(records[editingIndex-1].bonanzaId);
 
        e.preventDefault();
        if (!validateForm()) {
            return;
        }
        try{
            let endpoint = '/add-bonanza/1';
            let requestBody = { ...formValues };
            
            console.log("requestBody",requestBody)
            // If editing, update the endpoint and include bonanzaId in the request body
            if (editingIndex !== null) {
                endpoint = '/update-bonanza/1';
                requestBody = { ...requestBody, bonanzaId: records[editingIndex-1].bonanzaId };
            }
    
            const { data, error } = await apiCall('POST', endpoint, requestBody);
    
        if(data) {
            toast.success(data.message || "Record saved successfully");
            fetchRecords();
        } else if(error)
        {
            toast.error(error.message || "An error occurred")
        }
        // if (editingIndex !== null) {
        //     // Edit existing record
        //     const updatedRecords = records.map((record, index) =>
        //         index === editingIndex ? { ...formValues, status } : record
        //     );
        //     setRecords(updatedRecords);
        // } else {
        //     // Add new record
        //     setRecords([...records, { ...formValues, status }]);
        // }
        console.log('Saved values:', formValues);
        setShowInputs(false);
        setFormValues({
            bonanza_name: '',
            startDate: '',
            endDate: '',
            required_business: '',
            required_direct: '',
            required_team: '',
            reward_item: '',
            amount: '',
            planId: '' 
        });
        
    } catch (error) {
        toast.error(error.message || "An error occurred")
    }
    };

    const handleCancel = () => {
        setShowInputs(false);
    };

    const editRecord = async(record) => {  
        setFormValues({
            bonanza_name: record.bonanza_name,
            startDate: new Date(record.startDate).toISOString().split('T')[0],
            endDate: new Date(record.endDate).toISOString().split('T')[0],
            required_business: record.required_business,
            required_direct: record.required_direct,
            required_team: record.required_team,
            reward_item: record.instant_income.reward_item,
            amount: record.instant_income.amount,
            planId: record.planId,
        });
        setEditingIndex(record.bonanzaId);
        setShowInputs(true);
    };

    const handlePlanChange = (e) => {
        setFormValues({ ...formValues, planId: e.target.value });
    };

    const handleStatusChange = async (index) => {
        const updatedRecords = [...records];
        updatedRecords[index].status = !updatedRecords[index].status;
        setRecords(updatedRecords);

        try {
            const body = {
                ...updatedRecords,
                bonanzaId: updatedRecords[index].bonanzaId,
                status: updatedRecords[index].status}
            const { data, error } = await apiCall('POST', '/update-bonanza/1', body);

            if (data) {
                toast.success(data.message || "Status updated successfully");
                fetchRecords();
            } else if (error) {
                toast.error(error.message || "An error occurred");
            }
        } catch (error) {
            toast.error(error.message || "An error occurred");
        }
    };

    const handleDelete = async(bonanzaId) => {
        console.log("id",bonanzaId);
        try{           
            const {data,error} = await apiCall('POST','/delete-bonanza/1',{bonanzaId});
            
            if(data) {
                // const updatedRecords = records.filter((_, i) => i !== index);
                // setRecords(updatedRecords);
                toast.success(data.message || "Record deleted successfully");
                fetchRecords();
            }
            else if(error) {
                toast.error(error.message || "An error occur")
            }
        } catch (error) {
            toast.error(error.message || "An error occur")
        }
       
    };

    return (
        <React.Fragment>
            <Container>
        <div style={{ padding: "10px" }}>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom:"25px" }}>
                <select
                    value={formValues.planId}
                    onChange={handlePlanChange}
                    className="plan-select"
                >
                    <option value="" disabled>Select Plan ID</option>
                    {planOptions.map((option, index) => (
                        <option key={index} value={option}>{option}</option>
                    ))}
                </select>
                <button className="add-record-button" onClick={handleAddRecordClick}>
                    <i>
                        <CiCirclePlus />
                    </i>
                    {""} Add Record
                </button>
            </div>

            {showInputs && (
                <div className="bonanza-settings">
                    <div className="input-row">
                        <div className="input-group1">
                            <label>Plan ID:</label>
                            <select
                                value={formValues.planId}
                                onChange={handlePlanChange}
                                className="plan-select"
                            >
                                <option value="" disabled>Select Plan ID</option>
                                {planOptions.map((option, index) => (
                                    <option key={index} value={option}>{option}</option>
                                ))}
                            </select>
                        </div>
                        <div className="input-group1">
                            <label>Bonanza Name:</label>
                            <input
                                type="text"
                                placeholder="Enter Bonanza Name"
                                name="bonanza_name"
                                value={formValues.bonanza_name}
                                onChange={handleChange}
                                required
                            />
                        </div>
                        <div className="input-group1">
                            <label>Start Date:</label>
                            <input
                                type="date"
                                name="startDate"
                                value={formValues.startDate}
                                onChange={handleChange}
                                required
                            />
                        </div>
                        <div className="input-group1">
                            <label>End Date:</label>
                            <input
                                type="date"
                                name="endDate"
                                value={formValues.endDate}
                                onChange={handleChange}
                                required
                            />
                        </div>
                        <div className="input-group1">
                            <label>Required Business:</label>
                            <input
                                type="number"
                                placeholder="Enter Required Business"
                                name="required_business"
                                value={formValues.required_business}
                                onChange={handleChange}
                                required
                            />
                        </div>
                        <div className="input-group1">
                            <label>Required Direct:</label>
                            <input
                                type="number"
                                placeholder="Enter Required Direct"
                                name="required_direct"
                                value={formValues.required_direct}
                                onChange={handleChange}
                                required
                            />
                        </div>
                        <div className="input-group1">
                            <label>Required Team:</label>
                            <input
                                type="number"
                                placeholder="Enter Required Team"
                                name="required_team"
                                value={formValues.required_team}
                                onChange={handleChange}
                                required
                            />
                        </div>
                        <div className="input-group1">
                            <label>Reward Item:</label>
                            <input
                                type="text"
                                placeholder="Enter Reward Item"
                                name="reward_item"
                                value={formValues.reward_item}
                                onChange={handleChange}
                                required
                            />
                        </div>
                        <div className="input-group1">
                            <label>Amount:</label>
                            <input
                                type="number"
                                placeholder="Enter Amount"
                                name="amount"
                                value={formValues.amount }
                                onChange={handleChange}
                                required
                            />
                        </div>
                    </div>
                    <div className="button-container">
                        <Button type="submit" variant='primary' onClick={handleSave}>Save</Button>
                        <Button variant='secondary' className="ms-2" onClick={handleCancel}>Cancel</Button>
                    </div>
                </div>
            )}

            {/* Table displaying records */}
            <h2>Records</h2>
            {records.length > 0 ? (
                <div id="directIncomeDiv">
                <Row id="directTableRow" className="text-center">
                                <Col md="12" className="p-0">
                                    <div style={{ width: "100%" }}>
                                        <table>
                        <tr>
                            <th>S.No.</th>    
                            <th>Bonanza Name</th>
                            <th>Start Date</th>
                            <th>End Date</th>
                            <th>Required Business</th>
                            <th>Required Direct</th>
                            <th>Required Team</th>
                            <th>Reward Item</th>
                            <th>Amount</th>
                            <th>Status</th>
                            <th>Edit</th>
                            <th>Delete</th> 
                        </tr>
                        {records.map((record, index) => (
                            <tr key={index}>
                                <td data-label="S.No.">{index+1}</td> 
                                <td data-label="Bonanza Name">{record.bonanza_name}</td>
                                <td data-label="Start Date">{record.startDate}</td>
                                <td data-label="End Date">{record.endDate}</td>
                                <td data-label="Required Business">{record.required_business}</td>
                                <td data-label="Required Direct">{record.required_direct}</td>
                                <td data-label="Required Team">{record.required_team}</td>
                                <td data-label="Reward Item">{record.instant_income.reward_item}</td>
                                <td data-label="Amount">{record.instant_income.amount }</td>
                                <td data-label="Status">
                                    {<div className="toggle-container">
                                        <input
                                        type="checkbox"                                      
                                        checked={record.status}
                                    onChange={() => handleStatusChange(index)}/>
                                {/* <span className="status-label">{status ? '1' : '0'}</span> */}
                            </div>}
                            </td>
                                <td>
                                    <button className='block-btn' onClick={() => editRecord(record)}>Edit</button>
                                </td>
                                <td>
                                    <button className='block-btn' onClick={() => handleDelete(record.bonanzaId)}>Delete</button>
                                </td> 
                            </tr>
                        ))}
                </table>
                            </div>
                        </Col>
                    </Row>
        </div>
            ) : (
                <p>No records found</p>
            )}
        </div>
        <ToastContainer className="toast-container" />
        </Container>
        </React.Fragment>
    );
}
